import queryString from "query-string";
import api from "../../api";
import httpClient from "../../http/http-client";
import { RECEIVE_SERMONS } from "../modules/sermons";

const REQUEST = "llc-archive/sermon-filter/REQUEST";
export const requestOptions = () => ({
  type: REQUEST
});

const RECEIVE = "llc-archive/sermon-filter/RECEIVE";
export const receiveOptions = options => ({
  type: RECEIVE,
  options
});

const OPTIONS_UPDATED = "llc-archive/sermon-filter/OPTIONS_UPDATED";
export const optionsUpdated = options => ({
  type: OPTIONS_UPDATED,
  options
});

export const updateSelectedOptions = (options, history) => (
  dispatch,
  getState
) => {
  const previousSelected = selectSelected(getState());
  let params = queryString.parse(history.location.search);

  if (previousSelected) {
    for (let option of previousSelected) {
      delete params[option.filter_name];
    }
  }

  if (options) {
    for (let option of options) {
      params[option.filter_name] = option.field_value;
    }
  }

  dispatch(optionsUpdated(options));

  const query = queryString.stringify(params);
  history.push(`/sermons?${query}`);
};

export const fetchOptions = search => dispatch => {
  if (!search || search.length === 0) {
    dispatch(receiveOptions([]));
    return;
  }

  return httpClient
    .get(
      `${api.typeahead}?query=${search}&types=bible_book,date,minister,holiday,event,start_chapter,end_chapter,start_verse,end_verse`
    )
    .then(resp => resp.json())
    .then(suggestions => dispatch(receiveOptions(suggestions)));
};

const initialState = {
  options: [],
  selected: [],
  isLoading: false
};

export const selectOptions = state => state.sermonFilterState.options;

export const selectIsLoading = state => state.sermonFilterState.isLoading;

export const selectSelected = state => state.sermonFilterState.selected;

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case RECEIVE:
      return {
        ...state,
        options: action.options,
        isLoading: false
      };
    case OPTIONS_UPDATED:
      return {
        ...state,
        selected: action.options
      };
    case RECEIVE_SERMONS:
      return {
        ...state,
        selected: action.meta.filters
      };
    default:
      return state;
  }
}
