const SHOW_SNACKBAR = "llc-archive/snackbar/SHOW_SNACKBAR";
export const showSnackbar = (message, severity) => ({
  type: SHOW_SNACKBAR,
  message,
  severity
});

const CLOSE_SNACKBAR = "llc-archive/snackbar/CLOSE_SNACKBAR";
export const closeSnackbar = () => ({
  type: CLOSE_SNACKBAR
});

const initialState = {
  message: "",
  severity: ""
};

export const selectMessage = state => state.snackbarState.message;

export const selectSeverity = state => state.snackbarState.severity;

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SHOW_SNACKBAR:
      return {
        ...state,
        message: action.message,
        severity: action.severity || "success"
      };

    case CLOSE_SNACKBAR:
      return {
        ...state,
        message: ""
      };

    default:
      return state;
  }
}
