import api from "../../api";
import httpClient from "../../http/http-client";
import routes from "../../route/routes";
import { showSnackbar } from "./snackbar";


export const saveForm = (sermon, formData, history) => dispatch => {

  return httpClient
    .put(api.sermons + '/' + sermon.id, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(formData)
    })
    .then(resp => resp.json())
    .then(resp => {

      if (resp.message) {
        dispatch(showSnackbar(resp.message, "error"));
        throw new Error(resp.message);
      }
      else {
        dispatch(showSnackbar("Sermon Updated!"));
      }

      return resp;
    })
    .then(resp => {
      history.push('/sermons/play/' + sermon.id);
    })
    .catch(error => {
      dispatch(showSnackbar(error.message, "error"));
    });
};

export const cancelForm = (sermon, history) => dispatch => {
  history.push(routes.SERMON_LIST);
};

const initialState = {
  isLoading: false
};

export const selectIsLoading = state => state.sermonFormState.isLoading;

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
  
    default:
      return state;
  }
}
