
import React from "react";
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

function UserEmail({email, verified}) {

    let content;
    if (verified === true) {
        content = (<div>{email} <VerifiedUserIcon /></div>)
    }
    else {
        content = (<div>{email}</div>)
    }
    return content;
}

export default UserEmail;