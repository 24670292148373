import { makeStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import InfiniteScroll from "../common/InfiniteScroll";
import LoadingIndicator from "../common/LoadingIndicator";
import {
  fetchSermons,
  loadMoreSermons,
  selectIsLastPage,
  selectIsLoading,
  selectSermons
} from "../redux/modules/sermons";
import routes from "../route/routes";
import SermonListLoadingEffect from "./SermonListLoadingEffect";
import Sermons from "./Sermons";

const useStyles = makeStyles(theme => ({
  container: {
    position: "relative"
  },
  loading: {
    top: theme.spacing(2),
    right: theme.spacing(2),
    position: "absolute"
  },
  noData: {
    textAlign: "center",
    fontSize: theme.typography.h4.fontSize,
    color: theme.palette.grey[500]
  },
  loadingMore: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(6),
    textAlign: "center"
  }
}));

function SermonsContainer({
  sermons,
  fetchSermons,
  isLastPage,
  loadMore,
  isLoading,
  history
}) {
  const classes = useStyles();
  useEffect(() => {
    fetchSermons();
  }, [fetchSermons]);

  const selectSermon = sermon => {
    history.push(routes.SERMON_DETAILS.replace(":sermonId", sermon.id));
  };

  let content;
  if (!isLoading && !sermons.length) {
    content = <div className={classes.noData}>No sermons found</div>;
  } else {
    content = (
      <React.Fragment>
        <Paper className={classes.container}>
          {!sermons.length && isLoading ? (
            <SermonListLoadingEffect />
          ) : (
            <Sermons sermons={sermons} select={selectSermon}></Sermons>
          )}
        </Paper>
        <div className={classes.loadingMore}>
          {!isLastPage && (
            <React.Fragment>
              <LoadingIndicator visible={true} />
              <InfiniteScroll
                isLoading={isLoading}
                loadMore={loadMore}
              ></InfiniteScroll>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className={classes.container}>{content}</div>
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  sermons: selectSermons(state),
  isLoading: selectIsLoading(state),
  isLastPage: selectIsLastPage(state)
});

const mapDispatchToProps = (dispatch, props) => ({
  fetchSermons: () => dispatch(fetchSermons(props.history)),
  loadMore: () => dispatch(loadMoreSermons(props.history))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SermonsContainer)
);
