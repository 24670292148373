import Button from "@material-ui/core/Button";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import React, { useState } from "react";
import LoadingIndicator from "../../common/LoadingIndicator";

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(2),
    borderTopStyle: "dashed",
    borderTopWidth: 1,
    borderTopColor: theme.palette.grey[500]
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    borderTopStyle: "dashed",
    borderTopWidth: 1,
    borderTopColor: theme.palette.grey[500],
    padding: theme.spacing(2)
  },
  footerButton: {
    flex: 1,
    marginRight: theme.spacing(1)
  },
  formGroup: {
    marginTop: theme.spacing(2)
  },
  hidden: {
    display: "none"
  }
}));

const SermonFormUpload = ({ upload, cancel, isLoading }) => {
  const [file, setFile] = useState(null);

  const handleSubmit = event => {
    event.preventDefault();
    upload(file);
  };

  const handleFileChange = event => {
    setFile(event.target.files[0]);
  };

  const classes = useStyles();

  let footer;
  if (isLoading) {
    footer = (
          <LoadingIndicator visible={true} />
         )
  } else {
    footer = (
      <div>
        <Button
          className={classes.footerButton}
          variant="contained"
          color="primary"
          type="submit"
        >
          Upload
        </Button>
        <Button className={classes.footerButton} onClick={cancel}>
          Cancel
        </Button>
      </div>
    )
  }


  return (
    <form onSubmit={handleSubmit}>
      <Stepper activeStep={0}>
        <Step>
          <StepLabel>Upload file</StepLabel>
        </Step>
        <Step>
          <StepLabel>Enter sermon details</StepLabel>
        </Step>
      </Stepper>
      <div className={classes.content}>
        <div className={classes.formGroup}>
          <input
            id="file-upload"
            type="file"
            name="sermon_file"
            onChange={handleFileChange}
            required
            className={classes.hidden}
          />
          <label htmlFor="file-upload">
            <Button
              variant="outlined"
              color="default"
              component="span"
              startIcon={<CloudUploadIcon />}
            >
              Choose Sermon File
            </Button>
          </label>
          <p>{file ? file.name : "No file chosen"}</p>
        </div>
      </div>
      <div className={classes.footer}>
        {footer}
      </div>
    </form>
  );
};

export default SermonFormUpload;
