import queryString from "query-string";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  fetchCongregations,
  goToCongregation,
  selectCongregations
} from "../redux/modules/congregations";
import CongregationMenuItems from "./CongregationMenuItems";

const mapStateToProps = (state, props) => {
  const params = queryString.parse(props.history.location.search);
  return {
    congregations: selectCongregations(state),
    selectedId: params.congregation_id || 0
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  fetchCongregations: () => dispatch(fetchCongregations()),
  select: congregationId =>
    dispatch(goToCongregation(congregationId, props.history))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CongregationMenuItems)
);
