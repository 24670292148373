import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import books from "./books.json";

const useStyles = makeStyles(theme => ({
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  modal: {
    width: 384,
    maxHeight: "100%",
    overflowY: "auto",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  optionButton: {
    width: "20%",
    minWidth: "20%"
  },
  title: {
    marginTop: 0
  }
}));


const getBibleReferenceDisplay = state => {
  let title = "";
  if (state.book) {
    title += state.book.name;
  }
  if (state.chapter) {
    title += " " + state.chapter;
  }
  if (state.start_verse) {
    title += ":" + state.start_verse;
  }
  if (state.end_verse) {
    title += "-" + state.end_verse;
  }
  return title;
};

const BibleReferencePicker = ({ open, onSelect, onClose }) => {
  const initialState = {
    book: null,
    chapter: null,
    start_verse: null,
    end_verse: null
  };
  const [state, setState] = useState(initialState);

  const classes = useStyles();

const getBookOptions = onSelect =>
  books.map(book => (
    <Button
      className={classes.optionButton}
      color={book.testament === "OLD" ? "primary" : "secondary"}
      key={book.book_id}
      onClick={() => onSelect(book)}
    >
      {book.abbrev_name}
    </Button>
  ));

const getChapterOptions = (book, onSelect) =>
  Object.keys(book.chapters).map(chapter => (
    <Button
      className={classes.optionButton}
      key={chapter}
      onClick={() => onSelect(chapter)}
    >
      {chapter}
    </Button>
  ));

const getStartVerseOptions = (book, chapter, onSelect) =>
  [...Array(book.chapters[chapter]).keys()].map(verse => (
    <Button
      className={classes.optionButton}
      key={verse + 1}
      onClick={() => onSelect(verse + 1)}
    >
      {verse + 1}
    </Button>
  ));

const getEndVerseOptions = (book, chapter, start_verse, onSelect) =>
  [...Array(book.chapters[chapter]).keys()].map(verse => (
    <Button
      className={classes.optionButton}
      disabled={verse + 1 < start_verse}
      key={verse + 1}
      onClick={() => onSelect(verse + 1)}
    >
      {verse + 1}
    </Button>
  ));

  const onBookSelect = book =>
    setState({
      ...state,
      book
    });

  const onChapterSelect = chapter =>
    setState({
      ...state,
      chapter
    });

  const onStartVerseSelect = start_verse =>
    setState({
      ...state,
      start_verse
    });

  const onEndVerseSelect = end_verse =>
    setState({
      ...state,
      end_verse
    });

  const onBack = () => {
    if (state.end_verse) {
      setState({
        ...state,
        end_verse: null
      });
    } else if (state.start_verse) {
      setState({
        ...state,
        start_verse: null
      });
    } else if (state.chapter) {
      setState({
        ...state,
        chapter: null
      });
    } else if (state.book) {
      setState({
        ...state,
        book: null
      });
    }
  };

  const close = () => {
    onClose();
    setState(initialState);
  };

  const title = getBibleReferenceDisplay(state);

  let options;
  if (!state.book) {
    options = getBookOptions(onBookSelect);
  } else if (!state.chapter) {
    options = getChapterOptions(state.book, onChapterSelect);
  } else if (!state.start_verse) {
    options = getStartVerseOptions(
      state.book,
      state.chapter,
      onStartVerseSelect
    );
  } else if (!state.end_verse) {
    options = getEndVerseOptions(
      state.book,
      state.chapter,
      state.start_verse,
      onEndVerseSelect
    );
  } else {
    onSelect(state);
    close();
  }

  return (
    <Modal className={classes.center} open={open} onClose={close}>
      <div className={classes.modal}>
        {title ? <h3 className={classes.title}>{title}</h3> : ""}
        {state.book ? (
          <Button className={classes.optionButton} onClick={onBack}>
            {"<<"}
          </Button>
        ) : (
          ""
        )}
        {options}
      </div>
    </Modal>
  );
};

export default BibleReferencePicker;
