import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchMinisters, selectMinisters } from "../../redux/modules/ministers";
import MinisterList from "./MinisterList";

function MinisterListContainer({ fetchMinisters, ministers }) {
  useEffect(() => {
    fetchMinisters();
  }, [fetchMinisters]);

  return <MinisterList ministers={ministers} />;
}

const mapStateToProps = state => ({
  ministers: selectMinisters(state)
});

const mapDipsatchToProps = dispatch => ({
  fetchMinisters: () => dispatch(fetchMinisters())
});

export default connect(
  mapStateToProps,
  mapDipsatchToProps
)(MinisterListContainer);
