import queryString from "query-string";
import api from "../../api";
import httpClient from "../../http/http-client";
import routes from "../../route/routes";
import { showSnackbar } from "./snackbar";

const REQUEST_ORPHANED_FILES = "llc-archive/audio-files/REQUEST_ORPHANED_FILES";
const requestOrphanedAudioFiles = () => ({
  type: REQUEST_ORPHANED_FILES
});

const RECEIVE_ORPHANED_FILES = "llc-archive/audio-files/RECEIVE_ORPHANED_FILES";
const receiveOrphanedAudioFiles =  (files, meta) => ({
  type: RECEIVE_ORPHANED_FILES,
  files,
  meta
});

const REQUEST_MORE_ORPHANED_FILES = "llc-archive/sermon/REQUEST_MORE_ORPHANED_FILES";
export const requestMoreOrphanedFiles = () => ({
  type: REQUEST_MORE_ORPHANED_FILES
});

const RECEIVE_MORE_ORPHANED_FILES = "llc-archive/sermon/RECEIVE_MORE_ORPHANED_FILES";
export const receiveMoreOrphanedFiles = (files, meta) => ({
  type: RECEIVE_MORE_ORPHANED_FILES,
  files,
  meta
});

const REQUEST_FILE = "llc-archive/audio-files/REQUEST_FILE";
const requestFile = () => ({
  type: REQUEST_FILE
});

const RECEIVE_FILE = "llc-archive/audio-files/RECEIVE_FILE";
const receiveFile = file => ({
  type: RECEIVE_FILE,
  file
});

export const fetchOrphanedAudioFiles = history => dispatch => {
  let params = queryString.parse(history.location.search);
  dispatch(requestOrphanedAudioFiles());
  return httpClient
    .get(`${api.audioFileOrphans}?${queryString.stringify(params)}`)
    .then(resp => {
      if (!resp.ok) {
        const error = (resp.data && resp.data.message) || resp.statusText;
        return Promise.reject(error);
      }
      return resp;
    })
    .then(resp => resp.json())
    .then(resp => dispatch(receiveOrphanedAudioFiles(resp.audio_files, resp.meta)));
};


export const loadMoreOrphanedFiles = history => (dispatch, getState) => {
  let params = queryString.parse(history.location.search);
  const state = getState();
  const isLastPage = selectIsLastPage(state);
  const isLoading = selectIsLoading(state);
  const meta = selectMeta(state);

  if (!isLastPage && !isLoading) {
    params = {
      ...params,
      page_number: meta.page.page_number + 1
    };
    dispatch(requestMoreOrphanedFiles());
    return httpClient
      .get(`${api.audioFileOrphans}?${queryString.stringify(params)}`)
      .then(resp => resp.json())
      .then(resp => dispatch(receiveMoreOrphanedFiles(resp.audio_files, resp.meta)));
  }
};

export const fetchFile = fileId => (dispatch, getState) => {
  const file = selectFile(fileId, getState());
  if (file) {
    return;
  }

  dispatch(requestFile());
  return httpClient
    .get(api.audioFile(fileId))
    .then(resp => {
      if (!resp.ok) {
        throw new Error("Failed to retrieve file");
      }
      return resp;
    })
    .then(resp => resp.json())
    .then(resp => dispatch(receiveFile(resp)))
    .catch(error => {
      dispatch(showSnackbar(error.message, "error"));
    });
};

export const createSermonFromAudioFile = (file, history) => dispatch => {
  history.push(`${routes.SERMON_CREATE}?audio_file_id=${file.id}`);
};

const initialState = {
  files: [],
  meta: {},
  isLoading: false
};

export const selectOrphanedAudioFiles = state =>
  state.audioFileState.files;

export const selectIsLoading = state => state.audioFileState.isLoading;

export const selectMeta = state => state.audioFileState.meta;

export const selectIsLastPage = state =>
  state.audioFileState.meta.page &&
  (state.audioFileState.meta.page.page_number || 0) ===
    state.audioFileState.meta.page.total_pages - 1;

export const selectFile = (fileId, state) =>
  state.audioFileState.files.find(file => file.id === fileId);

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST_ORPHANED_FILES:
    case REQUEST_MORE_ORPHANED_FILES:
      return {
        ...state,
        isLoading: true
      };

    case RECEIVE_ORPHANED_FILES:
      return {
        ...state,
        files: action.files,
        meta: action.meta,
        isLoading: false
      };

    case RECEIVE_MORE_ORPHANED_FILES:
      return {
        ...state,
        files: [...state.files, ...action.files],
        meta: action.meta,
        isLoading: false
      };

    case RECEIVE_FILE:
      return {
        ...state,
        files: [...state.files, action.file]
      };

    default:
      return state;
  }
}
