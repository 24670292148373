import { AppBar, makeStyles, Toolbar } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import queryString from "query-string";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import LoginButtonContainer from "../../auth/LoginButtonContainer";
import { fetchFile } from "../../redux/modules/audio-files";
import { fetchSermonSuggestion } from "../../redux/modules/sermon-form";
import SermonFormDetailsContainer from "./SermonFormDetailsContainer";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4)
  },
  toolbar: theme.mixins.toolbar,
  grow: {
    flexGrow: 1
  }
}));

function SermonCreatePage({ history, fetchFile, fetchSermonSuggestion }) {
  const classes = useStyles();
  const params = queryString.parse(history.location.search);
  const audioFileId = params.audio_file_id;

  useEffect(() => {
    if (audioFileId) {
      fetchSermonSuggestion(audioFileId);
      fetchFile(audioFileId);
    }
  }, [fetchFile, fetchSermonSuggestion, audioFileId]);

  if (!audioFileId) {
    return <Redirect to="/sermons/upload" />;
  }

  return (
    <React.Fragment>
      <AppBar>
        <Toolbar>
          <span className={classes.grow}>Create Sermon</span>
          <LoginButtonContainer />
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm" className={classes.root}>
        <div className={classes.toolbar} />
        <Paper>
          <SermonFormDetailsContainer />
        </Paper>
      </Container>
    </React.Fragment>
  );
}

const mapDispatchToProps = dispatch => ({
  fetchFile: audioFileId => dispatch(fetchFile(audioFileId)),
  fetchSermonSuggestion: audioFileId =>
    dispatch(fetchSermonSuggestion(audioFileId))
});

export default connect(null, mapDispatchToProps)(SermonCreatePage);
