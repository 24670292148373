import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  cancelForm,
  saveForm,
  selectHoliday
} from "../../redux/modules/holidays";
import HolidayDetailsForm from "./HolidayDetailsForm";

const HolidayDetailsFormContainer = ({
  holiday,
  saveForm,
  cancel
}) => {
  return (
    <HolidayDetailsForm
      holiday={holiday}
      saveForm={saveForm}
      cancel={cancel}
    />
  );
};

const getHolidayIdParam = props =>
  Number.parseInt(props.match.params.holidayId, 10);

const mapStateToProps = (state, props) => ({
  holiday: selectHoliday(getHolidayIdParam(props), state)
});

const mapDispatchToProps = (dispatch, props) => ({
  saveForm: formData => dispatch(saveForm(formData, props.history)),
  cancel: () => dispatch(cancelForm(props.history))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HolidayDetailsFormContainer)
);
