import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Search from "../common/Search";
import {
  fetchOptions,
  selectIsLoading,
  selectOptions,
  selectSelected,
  updateSelectedOptions
} from "../redux/modules/sermon-filters";

const mapStatesToProps = state => ({
  options: selectOptions(state),
  selected: selectSelected(state),
  isLoading: selectIsLoading(state),
  placeholder: "Search...",
  noOptionsMessage: data =>
    data.inputValue.length ? "No results found" : "Start typing to search"
});

const mapDispatchToProps = (dispatch, props) => ({
  onSearch: search => {
    dispatch(fetchOptions(search));
    return undefined;
  },
  onSelect: options => dispatch(updateSelectedOptions(options, props.history))
});

export default withRouter(
  connect(mapStatesToProps, mapDispatchToProps)(Search)
);
