const getHeaders = () => ({
  Authorization: `Bearer ${localStorage.getItem("access_token")}`
});

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  get: (resource, init = {}) =>
    fetch(resource, {
      ...init,
      headers: {
        ...getHeaders(),
        ...(init.headers || {})
      }
    }),

  post: (resource, init = {}) =>
    fetch(resource, {
      ...init,
      method: "POST",
      headers: {
        ...getHeaders(),
        ...(init.headers || {})
      }
    }),

  put: (resource, init = {}) =>
    fetch(resource, {
      ...init,
      method: "PUT",
      headers: {
        ...getHeaders(),
        ...(init.headers || {})
      }
    }),

  del: (resource, init = {}) =>
    fetch(resource, {
      ...init,
      method: "DELETE",
      headers: {
        ...getHeaders(),
        ...(init.headers || {})
      }
    })
};
