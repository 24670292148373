import { Button, makeStyles } from "@material-ui/core";
import red from "@material-ui/core/colors/red";
import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  form: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column"
  },
  title: {
    margin: 0
  },
  formGroup: {
    marginTop: theme.spacing(2)
  },
  label: {
    marginBottom: theme.spacing(1),
    display: "block"
  },
  input: {
    width: "100%"
  },
  footer: {
    display: "flex",
    flexDirection: "column"
  },
  footerButton: {
    flex: 1,
    marginTop: theme.spacing(2)
  },
  error: {
    color: red[500]
  }
}));

const ChangePasswordForm = ({ changePassword, cancel, errorMessage }) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    username: "",
    old_password: "",
    new_password: ""
  });

  const handleSubmit = event => {
    event.preventDefault();
    changePassword(formData);
  };

  const handleInputChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const cancelLink = React.forwardRef((linkProps, ref) => (
    <Link to={"/login"} {...linkProps} innerRef={ref} />
  ));

  return (
    <React.Fragment>
      <form className={classes.form} onSubmit={handleSubmit}>
        <h2 className={classes.title}>Change Password</h2>

        <div className={classes.formGroup}>
          <TextField
            name="username"
            label="Username"
            variant="outlined"
            className={classes.input}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className={classes.formGroup}>
          <TextField
            name="old_password"
            label="Old Password"
            variant="outlined"
            type="password"
            className={classes.input}
            onChange={handleInputChange}
            required
          />
        </div>


        <div className={classes.formGroup}>
          <TextField
            name="new_password"
            label="New Password"
            variant="outlined"
            type="password"
            className={classes.input}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className={classes.footer}>
          <Button
            className={classes.footerButton}
            variant="contained"
            color="primary"
            size="large"
            type="submit"
          >
            Change Password
          </Button>
          <Button
            className={classes.footerButton}
            type="button"
            size="large"
            component={cancelLink}
          >
            Cancel Password Change
          </Button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default ChangePasswordForm;
