import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  fetchUnpublishedSermons,
  publishSermon,
  selectUnpublishedSermons
} from "../../redux/modules/unpublished-sermons";
import UnpublishedSermonList from "./UnpublishedSermonList";

function UnpublishedSermonListContainer({
  sermons,
  fetchSermons,
  publishSermon
}) {
  useEffect(() => {
    fetchSermons();
  }, [fetchSermons]);

  return (
    <UnpublishedSermonList sermons={sermons} publishSermon={publishSermon} />
  );
}

const mapStateToProps = state => ({
  sermons: selectUnpublishedSermons(state)
});

const mapDispatchToProps = (dispatch, props) => ({
  fetchSermons: () => dispatch(fetchUnpublishedSermons()),
  publishSermon: sermon => dispatch(publishSermon(sermon))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UnpublishedSermonListContainer)
);
