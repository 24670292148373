import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Paper
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import React from "react";
import Avatar from "@material-ui/core/Avatar";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper
  },
  listItemIcon: {
    justifyContent: "flex-end"
  },
  noData: {
    textAlign: "center",
    fontSize: theme.typography.h4.fontSize,
    color: theme.palette.grey[500]
  }
}));

function OrphanedAudioFileList({ files, select }) {
  const classes = useStyles();
  const getInitials = (user) => user && user.first_name.charAt(0) + user.last_name.charAt(0);

  return files && files.length ? (
    <Paper>
      <List className={classes.root}>
        {files.map((file, index) => (
          <React.Fragment key={index.toString()}>
            <ListItem button onClick={() => select(file)}>
              <ListItemAvatar>
                <Avatar>{getInitials(file.uploaded_by)}</Avatar>
              </ListItemAvatar>
              <ListItemText 
                primary={file.file_name} 
                secondary={file.artist + (file.album && " : " + file.album)} />
              <ListItemIcon className={classes.listItemIcon}>
                <ChevronRightIcon />
              </ListItemIcon>
            </ListItem>
            {index < files.length - 1 && <Divider component="li" />}
          </React.Fragment>
        ))}
      </List>
    </Paper>
  ) : (
    <div className={classes.noData}>No Orphaned Audio Files</div>
  );
}

export default OrphanedAudioFileList;
