import { CONGREGATION_SELECTED } from "./congregations";

const TOGGLE_MOBILE_DRAWER = "llc-archie/menu/TOGGLE_MOBILE_DRAWER";
export const toggleMobileDrawer = () => ({
  type: TOGGLE_MOBILE_DRAWER
});

const initialState = {
  mobileDrawerOpen: false
};

export const selectMobileDrawerOpen = state => state.menuState.mobileDrawerOpen;

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_MOBILE_DRAWER:
      return {
        ...state,
        mobileDrawerOpen: !state.mobileDrawerOpen
      };

    case CONGREGATION_SELECTED:
      return {
        ...state,
        mobileDrawerOpen: false
      };

    default:
      return state;
  }
}
