import {
  Button,
  makeStyles,
  Paper,
  Step,
  StepLabel,
  Stepper,
  TextField
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(2),
    borderTopStyle: "dashed",
    borderTopWidth: 1,
    borderTopColor: theme.palette.grey[500]
  },

  footer: {
    display: "flex",
    flexDirection: "row",
    borderTopStyle: "dashed",
    borderTopWidth: 1,
    borderTopColor: theme.palette.grey[500],
    padding: theme.spacing(2)
  },
  footerButton: {
    flex: 1,
    marginRight: theme.spacing(1)
  }
}));

function UserDetailsForm({ saveForm, cancel }) {
  const classes = useStyles();

  const [formData, setFormData] = React.useState({
    first_name: "",
    last_name: "",
    email: ""
  });

  const handleSubmit = event => {
    event.preventDefault();
    saveForm({
      ...formData,
      user_name: formData.email,
      password: "password1234"
    });
  };

  const handleDataChange = data => {
    setFormData({ ...formData, [data.target.name]: data.target.value });
  };

  return (
    <Paper>
      <form onSubmit={handleSubmit}>
        <Stepper activeStep={0}>
          <Step>
            <StepLabel>User Details</StepLabel>
          </Step>
          <Step>
            <StepLabel>Assign Roles</StepLabel>
          </Step>
        </Stepper>
        <div className={classes.content}>
          <TextField
            name="first_name"
            label="First Name"
            variant="outlined"
            required
            margin="normal"
            value={formData.first_name}
            onChange={handleDataChange}
            fullWidth
          />
          <TextField
            name="last_name"
            label="Last Name"
            variant="outlined"
            required
            margin="normal"
            value={formData.last_name}
            onChange={handleDataChange}
            fullWidth
          />
          <TextField
            name="email"
            type="email"
            label="Email"
            variant="outlined"
            required
            margin="normal"
            value={formData.email}
            onChange={handleDataChange}
            fullWidth
          />
        </div>
        <div className={classes.footer}>
          <Button
            className={classes.footerButton}
            type="submit"
            color="primary"
            variant="contained"
          >
            Save
          </Button>
          <Button
            className={classes.footerButton}
            onClick={cancel}
            type="button"
          >
            Cancel
          </Button>
        </div>
      </form>
    </Paper>
  );
}

export default UserDetailsForm;
