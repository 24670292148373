import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  fetchRoles,
  selectIsLoading,
  selectRoles
} from "../../redux/modules/roles";
import { assignRoles, cancelForm } from "../../redux/modules/user-form";
import { fetchUser, selectUser } from "../../redux/modules/users";
import UserRolesForm from "./UserRolesForm";

function UserRolesFormContainer({
  fetchRoles,
  fetchUser,
  saveForm,
  cancel,
  userId,
  user,
  roles,
  isLoading
}) {
  useEffect(() => {
    fetchRoles();
    fetchUser();
  }, [fetchRoles, fetchUser]);

  return (
    <UserRolesForm
      userId={userId}
      user={user}
      roles={roles}
      isLoading={isLoading}
      saveForm={saveForm}
      cancel={cancel}
    />
  );
}

const getUserIdParam = props => Number.parseInt(props.match.params.userId, 10);

const mapStateToProps = (state, props) => ({
  userId: getUserIdParam(props),
  user: selectUser(getUserIdParam(props), state),
  roles: selectRoles(state),
  isLoading: selectIsLoading(state)
});

const mapDispatchToProps = (dispatch, props) => ({
  fetchRoles: () => dispatch(fetchRoles()),
  fetchUser: () => dispatch(fetchUser(getUserIdParam(props))),
  saveForm: formData => dispatch(assignRoles(formData, props.history)),
  cancel: () => dispatch(cancelForm(props.history))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserRolesFormContainer)
);
