import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React, { useEffect } from "react";

function Congregations({
  congregations,
  selectedId,
  fetchCongregations,
  select
}) {
  useEffect(() => {
    fetchCongregations();
  }, [fetchCongregations]);

  return (
    <List>
      <ListItem button selected={+selectedId === 0} onClick={() => select(0)}>
        <ListItemText primary={"All"} />
      </ListItem>
      {congregations
        .sort((a, b) => {
          // force 'All' option to float to the top
          if (a.name === "All") {
            return -1;
          }
          if (b.name === "All") {
            return 1;
          }
          return a.name.localeCompare(b.name);
        })
        .map((congregation, index) => (
          <ListItem
            button
            key={index}
            selected={+selectedId === congregation.id}
            onClick={() => select(congregation.id)}
          >
            <ListItemText primary={congregation.name} />
          </ListItem>
        ))}
    </List>
  );
}

export default Congregations;
