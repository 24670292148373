import api from "../../api";
import httpClient from "../../http/http-client";
import routes from "../../route/routes";
import { showSnackbar } from "./snackbar";

const REQUEST_HOLIDAYS = "llc-archive/holiday/REQUEST_HOLIDAYS";
const requestHolidays = () => ({
  type: REQUEST_HOLIDAYS
});

const RECEIVE_HOLIDAYS = "llc-archive/holiday/RECEIVE_HOLIDAYS";
const receiveHolidays = holidays => ({
  type: RECEIVE_HOLIDAYS,
  holidays
});

const REQUEST_HOLIDAY = "llc-archive/holiday/REQUEST_HOLIDAY";
const requestHoliday = () => ({
  type: REQUEST_HOLIDAY
});

const RECEIVE_HOLIDAY = "llc-archive/holiday/RECEIVE_HOLIDAY";
const receiveHoliday = holiday => ({
  type: RECEIVE_HOLIDAY,
  holiday
});

export const fetchHolidays = () => dispatch => {
  dispatch(requestHolidays());
  return httpClient
    .get(api.holidays)
    .then(resp => resp.json())
    .then(resp => dispatch(receiveHolidays(resp)));
};

export const fetchHoliday = holidayId => (dispatch, getState) => {
  const holiday = selectHoliday(holidayId, getState());
  if (holiday) {
    return;
  }
  dispatch(requestHoliday());

  return httpClient
    .get(api.holiday(holidayId))
    .then(resp => resp.json())
    .then(resp => dispatch(receiveHoliday(resp)));
};

export const saveForm = (formData, history) => dispatch => {
  const editing = !!formData.id;
  const method = editing ? httpClient.put : httpClient.post;
  const url = editing ? api.holiday(formData.id) : api.holidays;

  return method(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(formData)
  })
    .then(resp => {
      if (resp.ok) {
        dispatch(showSnackbar("Holiday Saved!"));
        return resp.json();
      } else {
        const message =
          resp.status === 403
            ? "You don't have permission to edit holidays"
            : "Failed to save the holiday";
        console.log("resp", resp);
        throw new Error(message);
      }
    })
    .then(resp => {
      history.push(routes.ADMIN_HOLIDAY_LIST);
    })
    .catch(error => {
      dispatch(showSnackbar(error.message, "error"));
    });
};

export const cancelForm = history => dispatch => {
  history.push(routes.ADMIN_HOLIDAY_LIST);
};

const initialState = {
  holidays: [],
  isLoading: false
};

export const selectHolidays = state => state.holidayState.holidays;

export const selectHoliday = (holidayId, state) =>
  state.holidayState.holidays.find(holiday => holiday.id === holidayId);

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST_HOLIDAYS:
      return {
        ...state,
        isLoading: true
      };

    case RECEIVE_HOLIDAYS:
      return {
        ...state,
        isLoading: false,
        holidays: action.holidays
      };

    case RECEIVE_HOLIDAY:
      return {
        ...state,
        holidays: [...state.holidays, action.holiday]
      };

    default:
      return state;
  }
}
