import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import BibleReference from "../bible/bibleReference";
import DateFnsUtils from "@date-io/date-fns";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import BibleReferencePicker from "../bible/bibleReferencePicker";
import TypeaheadSelect from "../common/TypeaheadSelect";

const useStyles = makeStyles(theme => ({
  form: {
    display: "flex",
    flexDirection: "column"
  },
  formGroup: {
    marginTop: theme.spacing(2)
  },
  label: {
    marginBottom: theme.spacing(1),
    display: "block"
  },
  datePicker: {
    marginTop: 0
  },
  title: {
    margin: 0
  },
  content: {
    padding: theme.spacing(2)
  },

  footer: {
    display: "flex",
    flexDirection: "row",
    borderTopStyle: "dashed",
    borderTopWidth: 1,
    borderTopColor: theme.palette.grey[500],
    padding: theme.spacing(2)
  },
  footerButton: {
    flex: 1,
    marginRight: theme.spacing(1)
  },
  audio_info: {
    marginBottom: theme.spacing(1),
    display: "block"
  },
  audio_label: {
    marginRight:  theme.spacing(1),
    fontWeight: "bold"
  }

}));


function SermonEdit({ sermon, saveForm, cancel }) {
  const classes = useStyles();


  const today = new Date();

  const [
    isBibleReferencePickerOpen,
    setBibleReferencePickerOpen
  ] = React.useState(false);

  const [formData, setFormData] = React.useState({
    bible_references: sermon.bible_references,
    spoken_timestamp: sermon.spoken_timestamp,
    congregation_id: sermon.congregation.id,
    minister_id: sermon.minister.id,
    event_id: sermon.event.id,
    holiday_id: sermon.holiday.id
  });

  const [cancelDialogOpen, setCancelDialogOpen] = React.useState(false);

  const handleSubmit = event => {
    event.preventDefault();
    saveForm(sermon, formData);
  };

  const handleDateChange = date => {
    setFormData({ ...formData, spoken_timestamp: date });
  };

  const typeaheadChange = data => {
    setFormData({ ...formData, [data.filter_name]: data.field_value });
  };

  const bibleReferenceSelect = bibleReference => {
    setFormData({
      ...formData,
      bible_references: [
        ...formData.bible_references,
        {
          book_id: bibleReference.book.book_id,
          name: bibleReference.book.name,
          start_chapter: bibleReference.chapter,
          end_chapter: bibleReference.chapter,
          start_verse: bibleReference.start_verse,
          end_verse: bibleReference.end_verse
        }
      ]
    });
  };

  const bibleReferenceRemove = bibleReference => {
    const bible_references = [...formData.bible_references];
    const idx = bible_references.indexOf(bibleReference);
    bible_references.splice(idx, 1);

    setFormData({
      ...formData,
      bible_references
    });
  };


  return (
    <React.Fragment>

      <form className={classes.form} onSubmit={handleSubmit}>
        <div className={classes.content}>

      {sermon.audio_file && (

        <fieldset>
          <legend>MP3 File Tags</legend>

          <div className={classes.audio_info}>
            <label className={classes.audio_label} htmlFor="file_name">File Name:</label>
            <span className={classes.audio_field} id="file_name" name="file_name">{sermon.audio_file.file_name}</span>
          </div>

          <div className={classes.audio_info}>
            <label className={classes.audio_label} htmlFor="artist">Artist:</label>
            <span className={classes.audio_field}  id="artist" name="artist">{sermon.audio_file.artist}</span>
          </div>

          <div className={classes.audio_info}>
            <label className={classes.audio_label} htmlFor="album">Album:</label>
            <span className={classes.audio_field}  id="album" name="album">{sermon.audio_file.album}</span>
          </div>

          <div className={classes.audio_info}>
            <label className={classes.audio_label} htmlFor="comment">Comment:</label>
            <span className={classes.audio_field}  id="comment" name="comment">{sermon.audio_file.comment}</span>
          </div>

          <div className={classes.audio_info}>
            <label className={classes.audio_label} htmlFor="title">Title:</label>
            <span className={classes.audio_field}  id="title" name="title">{sermon.audio_file.title}</span>
          </div>

          {sermon.audio_file.uploaded_by && sermon.audio_file.uploaded_by.first_name && sermon.audio_file.uploaded_by.last_name && (
            <div className={classes.audio_info}>
              <label className={classes.audio_label} htmlFor="uploaded_by">Uploaded By:</label>
              <span className={classes.audio_field}  id="uploaded_by" name="uploaded_by">{sermon.audio_file.uploaded_by.first_name + " " + sermon.audio_file.uploaded_by.last_name}</span>
            </div>
          )}

        </fieldset>
        )}

          <div className={classes.formGroup}>
            <label className={classes.label}>Spoken Date</label>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className={classes.datePicker}
                disableToolbar
                maxDate={today}
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                value={formData.spoken_timestamp}
                onChange={handleDateChange}
                required={true}
              />
            </MuiPickersUtilsProvider>
          </div>

          <div className={classes.formGroup}>
            <label className={classes.label}>Congregation</label>
            <TypeaheadSelect
              type="congregation"
              onChange={typeaheadChange}
              required={true}
              label={sermon.congregation.name}
              value={sermon.congregation.id}
            />
          </div>

          <div className={classes.formGroup}>
            <label className={classes.label}>Minister</label>
            <TypeaheadSelect
              type="minister"
              onChange={typeaheadChange}
              required={true}
              label={sermon.minister.first_name + ' ' + sermon.minister.last_name}
              value={sermon.minister.id}
            />
          </div>

          <div className={classes.formGroup}>
            <label className={classes.label}>Event</label>
            <TypeaheadSelect
              type="event"
              onChange={typeaheadChange}
              required={false}
              placeholder={sermon.event.name}
              label={sermon.event.name}
              value={sermon.event.id}
            />
          </div>

          <div className={classes.formGroup}>
            <label className={classes.label}>Church Holiday</label>
            <TypeaheadSelect
              type="holiday"
              onChange={typeaheadChange}
              required={false}
              label={sermon.holiday.name && (sermon.holiday.name + ' : ' + sermon.holiday.theme)}
              value={sermon.holiday.id}
            />
          </div>

          <div className={classes.formGroup}>
            <label className={classes.label}>Bible References</label>
            <List disablePadding>
              {formData.bible_references.map((reference, idx) => (
                <ListItem key={idx}>
                  <ListItemIcon>
                    <IconButton
                      edge="start"
                      aria-label="delete"
                      onClick={() => bibleReferenceRemove(reference)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemIcon>
                  <ListItemText
                    primary={<BibleReference reference={reference} />}
                  />
                </ListItem>
              ))}
            </List>
            <div>
              <Button
                variant="outlined"
                type="button"
                onClick={() => setBibleReferencePickerOpen(true)}
              >
                Add Bible Reference
              </Button>
            </div>
          </div>

          <BibleReferencePicker
            open={isBibleReferencePickerOpen}
            onClose={() => setBibleReferencePickerOpen(false)}
            onSelect={reference => bibleReferenceSelect(reference)}
          />
        </div>

        <div className={classes.footer}>
          <Button
            className={classes.footerButton}
            variant="contained"
            color="primary"
            type="submit"
          >
            Save
          </Button>

          <Button
            className={classes.footerButton}
            type="button"
            onClick={() => setCancelDialogOpen(true)}
          >
            Cancel
          </Button>
        </div>
      </form>
      
      <Dialog
        open={cancelDialogOpen}
        onClose={() => setCancelDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Discard Changes?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sermon details will be discarded.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCancelDialogOpen(false)}>Cancel</Button>
          <Button color="secondary" onClick={cancel}>
            Discard
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default SermonEdit;
