import { AppBar, makeStyles, Toolbar } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import React from "react";
import ChangePasswordFormContainer from "./ChangePasswordFormContainer";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4)
  },
  toolbar: theme.mixins.toolbar,
  grow: {
    flexGrow: 1
  }
}));

const ChangePasswordPage = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <AppBar>
        <Toolbar>
          <span className={classes.grow}>Change Password</span>
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm" className={classes.root}>
        <div className={classes.toolbar} />
        <Paper>
          <ChangePasswordFormContainer />
        </Paper>
      </Container>
    </React.Fragment>
  );
};

export default ChangePasswordPage;
