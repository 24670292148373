import { makeStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import InfiniteScroll from "../../common/InfiniteScroll";
import LoadingIndicator from "../../common/LoadingIndicator";
import {
  createSermonFromAudioFile,
  fetchOrphanedAudioFiles,
  loadMoreOrphanedFiles,
  selectIsLastPage,
  selectIsLoading,
  selectOrphanedAudioFiles
} from "../../redux/modules/audio-files";
import OrphanedAudioFileList from "./OrphanedAudioFileList";

const useStyles = makeStyles(theme => ({
  container: {
    position: "relative"
  },
  loading: {
    top: theme.spacing(2),
    right: theme.spacing(2),
    position: "absolute"
  },
  noData: {
    textAlign: "center",
    fontSize: theme.typography.h4.fontSize,
    color: theme.palette.grey[500]
  },
  loadingMore: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(6),
    textAlign: "center"
  }
}));

function OrphanedAudioFileListContainer({
   files,
   fetchFiles,
   select,
   isLastPage,
   loadMore,
   isLoading,
   history
  }) {
  const classes = useStyles();

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  let content;
  if (!isLoading && !files.length) {
    content = <div className={classes.noData}>No Orphaned Audio Files</div>;
  } else {
        content = (
        <React.Fragment>
          <Paper className={classes.container}>
            {!files.length && isLoading ? (
              <LoadingIndicator visible={true} />
            ) : (
              <OrphanedAudioFileList files={files} select={select} />
            )}
          </Paper>
              <div className={classes.loadingMore}>
                {!isLastPage && (
                  <React.Fragment>
                    <LoadingIndicator visible={true} />
                    <InfiniteScroll
                      isLoading={isLoading}
                      loadMore={loadMore}
                    ></InfiniteScroll>
                  </React.Fragment>
                )}
              </div>
            </React.Fragment>
          );
  }

  return (
    <React.Fragment>
      <div className={classes.container}>{content}</div>
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  files: selectOrphanedAudioFiles(state),
  isLoading: selectIsLoading(state),
  isLastPage: selectIsLastPage(state)
});

const mapDispatchToProps = (dispatch, props) => ({
  fetchFiles: () => dispatch(fetchOrphanedAudioFiles(props.history)),
  loadMore: () => dispatch(loadMoreOrphanedFiles(props.history)),
  select: file => dispatch(createSermonFromAudioFile(file, props.history))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrphanedAudioFileListContainer)
);
