import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { authMiddleware } from "./authMiddleware";
import rootReducer from "./index";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(authMiddleware, thunk))
);

export default store;
