import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import routes from "../../route/routes";

function CongregationList({ congregations }) {
  const rowsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  return (
    <Paper>
      {congregations.length ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Congregation</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {congregations
                .sort((a, b) => a.name.localeCompare(b.name))
                .slice(
                  currentPage * rowsPerPage,
                  currentPage * rowsPerPage + rowsPerPage
                )
                .map((congregation, idx) => (
                  <TableRow key={idx}>
                    <TableCell>
                      {congregation.name}
                    </TableCell>
                    <TableCell>
                      {congregation.disabled ? <ErrorOutlineIcon /> : ''}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        size="small"
                        component={RouterLink}
                        to={routes.ADMIN_CONGREGATION_EDIT.replace(
                          ":congregationId",
                          congregation.id
                        )}
                      >
                        <EditIcon color="primary" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[]}
                  page={currentPage}
                  count={congregations.length}
                  rowsPerPage={rowsPerPage}
                  onChangePage={handlePageChange}
                ></TablePagination>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      ) : (
        ""
      )}
    </Paper>
  );
}

export default CongregationList;
