import {
  AppBar,
  Breadcrumbs,
  Container,
  Link,
  makeStyles,
  Toolbar,
  Typography
} from "@material-ui/core";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import LoginButtonContainer from "../../auth/LoginButtonContainer";
import {
  fetchHoliday,
  selectHoliday
} from "../../redux/modules/holidays";
import routes from "../../route/routes";
import HolidayDetailsFormContainer from "./HolidayDetailsFormContainer";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1)
  },
  grow: {
    flexGrow: 1
  },
  toolbar: theme.mixins.toolbar
}));

const extractHolidayId = match =>
  Number.parseInt(match.params.holidayId, 10);

function HolidayEditPage({ match, fetchHoliday, holiday }) {
  const classes = useStyles();
  const holidayId = extractHolidayId(match);

  useEffect(() => {
    fetchHoliday(holidayId);
  }, [fetchHoliday, holidayId]);

  return (
    <React.Fragment>
      <AppBar>
        <Toolbar>
          <span className={classes.grow}>Admin</span>
          <LoginButtonContainer />
        </Toolbar>
      </AppBar>
      <Container maxWidth="md" className={classes.root}>
        <div className={classes.toolbar} />
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} color="inherit" to={routes.ADMIN}>
            Admin
          </Link>
          <Link
            component={RouterLink}
            color="inherit"
            to={routes.ADMIN_HOLIDAY_LIST}
          >
            Holidays
          </Link>
          <Typography color="textPrimary">Edit Holiday</Typography>
        </Breadcrumbs>
        <Typography variant="h4" component="h1" gutterBottom>
          Edit Holiday
        </Typography>

        {holiday && <HolidayDetailsFormContainer />}
      </Container>
    </React.Fragment>
  );
}

const mapStateToProps = (state, props) => ({
  holiday: selectHoliday(extractHolidayId(props.match), state)
});

const mapDispatchToProps = dispatch => ({
  fetchHoliday: holidayId =>
    dispatch(fetchHoliday(holidayId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HolidayEditPage);
