import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  fetchHolidays,
  selectHolidays
} from "../../redux/modules/holidays";
import HolidayList from "./HolidayList";

function HolidayListContainer({ fetchHolidays, holidays }) {
  useEffect(() => {
    fetchHolidays();
  }, [fetchHolidays]);

  return <HolidayList holidays={holidays} />;
}

const mapStateToProps = state => ({
  holidays: selectHolidays(state)
});

const mapDipsatchToProps = dispatch => ({
  fetchHolidays: () => dispatch(fetchHolidays())
});

export default connect(
  mapStateToProps,
  mapDipsatchToProps
)(HolidayListContainer);
