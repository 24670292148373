import { Button, makeStyles, Paper, TextField } from "@material-ui/core";
import React, { useState } from "react";

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(2)
  },

  footer: {
    display: "flex",
    flexDirection: "row",
    borderTopStyle: "dashed",
    borderTopWidth: 1,
    borderTopColor: theme.palette.grey[500],
    padding: theme.spacing(2)
  },
  footerButton: {
    flex: 1,
    marginRight: theme.spacing(1)
  }
}));

function EventDetailsForm({ event, saveForm, cancel }) {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    name: "",
    ...event
  });

  const handleSubmit = event => {
    event.preventDefault();
    saveForm(formData);
  };

  const handleDataChange = data => {
    setFormData({ ...formData, [data.target.name]: data.target.value });
  };

  return (
    <Paper>
      <form onSubmit={handleSubmit}>
        <div className={classes.content}>
          <TextField
            name="name"
            label="Name"
            variant="outlined"
            required
            margin="normal"
            value={formData.name}
            onChange={handleDataChange}
            fullWidth
          />
        </div>
        <div className={classes.footer}>
          <Button
            className={classes.footerButton}
            type="submit"
            color="primary"
            variant="contained"
          >
            Save
          </Button>
          <Button
            className={classes.footerButton}
            onClick={cancel}
            type="button"
          >
            Cancel
          </Button>
        </div>
      </form>
    </Paper>
  );
}

export default EventDetailsForm;
