import { connect } from "react-redux";
import { withRouter } from "react-router";
import { cancelForm, saveForm } from "../../redux/modules/user-form";
import UserDetailsForm from "./UserDetailsForm";

const mapStateToProps = state => ({});

const mapDispatchToProps = (dispatch, props) => ({
  saveForm: formData => dispatch(saveForm(formData, props.history)),
  cancel: () => dispatch(cancelForm(props.history))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserDetailsForm)
);
