import {
    AppBar,
    Box,
    Breadcrumbs,
    Button,
    Container,
    Link,
    makeStyles,
    Toolbar,
    Typography
  } from "@material-ui/core";
  import AddIcon from "@material-ui/icons/Add";
  import React from "react";
  import { Link as RouterLink } from "react-router-dom";
  import LoginButtonContainer from "../../auth/LoginButtonContainer";
  import routes from "../../route/routes";
  import HolidayListContainer from "./HolidayListContainer";
  
  const useStyles = makeStyles(theme => ({
    root: {
      marginTop: theme.spacing(1)
    },
    grow: {
      flexGrow: 1
    },
    toolbar: theme.mixins.toolbar
  }));
  
  function HolidayListPage() {
    const classes = useStyles();
  
    return (
      <React.Fragment>
        <AppBar>
          <Toolbar>
            <span className={classes.grow}>Admin</span>
            <LoginButtonContainer />
          </Toolbar>
        </AppBar>
        <Container maxWidth="md" className={classes.root}>
          <div className={classes.toolbar} />
          <Breadcrumbs aria-label="breadcrumb">
            <Link component={RouterLink} color="inherit" to="/admin">
              Admin
            </Link>
            <Typography color="textPrimary">Holidays</Typography>
          </Breadcrumbs>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Typography variant="h4" component="h1" gutterBottom>
            Holidays
            </Typography>
            <Button
              component={RouterLink}
              to={routes.ADMIN_HOLIDAY_CREATE}
              startIcon={<AddIcon />}
              variant="contained"
              color="secondary"
            >
              Create Holiday
            </Button>
          </Box>
          <HolidayListContainer />
        </Container>
      </React.Fragment>
    );
  }
  
  export default HolidayListPage;
  