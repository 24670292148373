import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  closeSnackbar,
  selectMessage,
  selectSeverity
} from "../redux/modules/snackbar";

function SnackbarContainer({ message, severity, close }) {
  return (
    <Snackbar
      open={!!message}
      onClose={close}
      autoHideDuration={4000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <MuiAlert severity={severity} elevation={6} variant="filled">
        {message}
      </MuiAlert>
    </Snackbar>
  );
}

const mapStateToProps = state => ({
  message: selectMessage(state),
  severity: selectSeverity(state)
});

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(closeSnackbar())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SnackbarContainer)
);
