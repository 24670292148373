import Avatar from "@material-ui/core/Avatar";
import React from "react";

function MinisterAvatar(props) {

  const getInitials = (minister) => minister.first_name.charAt(0) + minister.last_name.charAt(0);

  return (
    <Avatar>{getInitials(props.minister)}</Avatar>
  )
}

export default MinisterAvatar;