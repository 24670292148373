import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { selectIsLoggedIn, selectRoles } from "../redux/modules/auth";

function SecuredRoute({
  component: Component,
  requiredRole,
  isLoggedIn,
  userRoles,
  ...rest
}) {
  const hasRole =
    isLoggedIn && !!userRoles.find(role => role.indexOf(requiredRole) > -1);

  return (
    <Route
      {...rest}
      render={props =>
        hasRole ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
}

const mapStateToProps = state => ({
  isLoggedIn: selectIsLoggedIn(state),
  userRoles: selectRoles(state)
});

export default connect(mapStateToProps, null)(SecuredRoute);
