import { 
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Paper
} from "@material-ui/core";
import {
  fetchSermon,
  selectIsLoading,
  selectSermon,
  unpublishSermon,
  deleteSermon
} from "../redux/modules/sermons";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LoadingIndicator from "../common/LoadingIndicator";
import SermonDetails from "./SermonDetails";
import HasRole from "../auth/HasRole";
import roles from "../auth/roles";
import routes from "../route/routes";

const useStyles = makeStyles(theme => ({
  container: {
    position: "relative"
  },
  loading: {
    top: theme.spacing(2),
    right: theme.spacing(2),
    position: "absolute"
  },
  noData: {
    textAlign: "center",
    fontSize: theme.typography.h4.fontSize,
    color: theme.palette.grey[500]
  },


  footer: {
    display: "flex",
    flexDirection: "row",
    borderTopStyle: "dashed",
    borderTopWidth: 1,
    borderTopColor: theme.palette.grey[500],
    padding: theme.spacing(2)
  },
  footerButton: {
    flex: 1,
    marginRight: theme.spacing(1)
  },
}));

function SermonDetailsContainer({ history, sermonId, sermon, fetchSermon, unpublishSermon, deleteSermon, isLoading }) {
  const classes = useStyles();

  const [sermonToUnpublish, setSermonToUnpublish] = React.useState(null);
  const [sermonToDelete, setSermonToDelete] = React.useState(null);

  const confirmUnpublish = () => {
    setSermonToUnpublish(null);
    unpublishSermon(sermonToUnpublish);
    history.push(routes.SERMON_LIST);
  };

  const cancelUnpublish = () => {
    setSermonToUnpublish(null);
  };

  const confirmDelete = () => {
    setSermonToDelete(null);
    deleteSermon(sermonToDelete);
    history.push(routes.SERMON_LIST);
  };

  const cancelDelete = () => {
    setSermonToDelete(null);
  };


  function handleEditSermon() {
    history.push("/sermons/edit/" + sermonId);
  }

  useEffect(() => {
    fetchSermon(sermonId);
  }, [fetchSermon, sermonId]);

  let content;
  if (sermon && sermon.id) {
    content = (
      <Paper className={classes.container}>
        <SermonDetails sermon={sermon}></SermonDetails>

        <HasRole role={roles.EDIT_SERMON}>
          <div className={classes.footer}>
            <Button
              className={classes.footerButton}
              variant="contained"
              color="primary"
              type="button"
              onClick={handleEditSermon}
            >
              Edit
            </Button>

            <HasRole role={roles.UNPUBLISH_SERMON}>
              <Button
                className={classes.footerButton}
                variant="contained"
                color="primary"
                type="button"
                onClick={() => setSermonToUnpublish(sermon)}
              >
                Unpublish
              </Button>
            </HasRole>

            <HasRole role={roles.DELETE_SERMON}>
              <Button
                className={classes.footerButton}
                variant="contained"
                color="secondary"
                type="button"
                onClick={() => setSermonToDelete(sermon)}
              >
                Delete
              </Button>
            </HasRole>

            <Dialog
              open={!!sermonToUnpublish}
              onClose={cancelUnpublish}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Unpublish Sermon?</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  This sermon will no longer be visible to the public.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={cancelUnpublish}>Cancel</Button>
                <Button onClick={confirmUnpublish} color="primary" autoFocus>
                  Unpublish
                </Button>
              </DialogActions>
            </Dialog>


            <Dialog
              open={!!sermonToDelete}
              onClose={cancelDelete}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Delete Sermon?</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  This sermon will permanently be deleted.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={cancelDelete}>Cancel</Button>
                <Button onClick={confirmDelete} color="primary" autoFocus>
                  Delete
                </Button>
              </DialogActions>
            </Dialog>

          </div>
        </HasRole>

      </Paper>
    );
  } else {
    content = <div className={classes.noData}>No sermon found</div>;
  }

  return (
    <React.Fragment>
      <div className={classes.container}>
        {content}
        <div className={classes.loading}>
          <LoadingIndicator visible={isLoading}></LoadingIndicator>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state, props) => ({
  sermonId: Number.parseInt(props.match.params.sermonId, 10),
  sermon: selectSermon(Number.parseInt(props.match.params.sermonId, 10), state),
  isLoading: selectIsLoading(state)
});

const mapDispatchToProps = dispatch => ({
  fetchSermon: params => dispatch(fetchSermon(params)),
  unpublishSermon: sermon => dispatch(unpublishSermon(sermon)),
  deleteSermon: sermon => dispatch(deleteSermon(sermon))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SermonDetailsContainer)
);
