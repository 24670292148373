import {
  Button,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField
} from "@material-ui/core";
import React, { useState } from "react";

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(2)
  },

  footer: {
    display: "flex",
    flexDirection: "row",
    borderTopStyle: "dashed",
    borderTopWidth: 1,
    borderTopColor: theme.palette.grey[500],
    padding: theme.spacing(2)
  },
  footerButton: {
    flex: 1,
    marginRight: theme.spacing(1)
  }
}));

function MinisterDetailsForm({ minister, congregations, saveForm, cancel }) {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    nick_name: "",
    congregation_id: "",
    ...minister
  });

  const handleSubmit = event => {
    event.preventDefault();
    saveForm(formData);
  };

  const handleDataChange = data => {
    setFormData({ ...formData, [data.target.name]: data.target.value });
  };

  return (
    <Paper>
      <form onSubmit={handleSubmit}>
        <div className={classes.content}>
          <TextField
            name="first_name"
            label="First Name"
            variant="outlined"
            required
            margin="normal"
            value={formData.first_name}
            onChange={handleDataChange}
            fullWidth
          />
          <TextField
            name="last_name"
            label="Last Name"
            variant="outlined"
            required
            margin="normal"
            value={formData.last_name}
            onChange={handleDataChange}
            fullWidth
          />
          <TextField
            name="nick_name"
            label="Nickname"
            variant="outlined"
            margin="normal"
            value={formData.nick_name}
            onChange={handleDataChange}
            fullWidth
          />

          <FormControl margin="normal" variant="outlined" fullWidth>
            <InputLabel id="congregation-label">Congregation</InputLabel>
            <Select
              name="congregation_id"
              labelId="congregation-label"
              label="Congregation"
              value={formData.congregation_id}
              onChange={handleDataChange}
            >
              {congregations.map(congregation => (
                <MenuItem value={congregation.id} key={congregation.id}>
                  {congregation.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.footer}>
          <Button
            className={classes.footerButton}
            type="submit"
            color="primary"
            variant="contained"
          >
            Save
          </Button>
          <Button
            className={classes.footerButton}
            onClick={cancel}
            type="button"
          >
            Cancel
          </Button>
        </div>
      </form>
    </Paper>
  );
}

export default MinisterDetailsForm;
