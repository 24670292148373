import api from "../../api";
import httpClient from "../../http/http-client";
import routes from "../../route/routes";
import { showSnackbar } from "./snackbar";

export const saveForm = (formData, history) => dispatch => {
  return httpClient
    .post(api.users, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(formData)
    })
    .then(resp => {
      if (resp.ok) {
        dispatch(showSnackbar("User Created!"));
        return resp.json();
      } else {
        throw new Error("Failed to save the user");
      }
    })
    .then(resp => {
      history.push(routes.ADMIN_EDIT_USER_ROLES.replace(":userId", resp.id));
    })
    .catch(error => {
      dispatch(showSnackbar(error.message, "error"));
    });
};

export const cancelForm = history => dispatch => {
  history.push(routes.ADMIN_USER_LIST);
};

export const assignRoles = (formData, history) => dispatch => {
  let assignRoleRequests;
  if (formData.congregations && formData.congregations.length) {
    assignRoleRequests = formData.congregations.map(congregation => ({
      role_id: formData.role_id,
      congregation_id: congregation.id
    }));
  } else {
    assignRoleRequests = [
      {
        role_id: formData.role_id
      }
    ];
  }

  return httpClient
    .post(api.userRoles(formData.userId), {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(assignRoleRequests)
    })
    .then(resp => {
      if (resp.ok) {
        dispatch(showSnackbar("User Saved"));
        return resp;
      } else {
        throw new Error("Failed to assign roles");
      }
    })
    .then(resp => {
      history.push(routes.ADMIN_USER_LIST);
    })
    .catch(error => {
      dispatch(showSnackbar(error.message, "error"));
    });
};

const initialState = {};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}
