import {
  AppBar,
  Breadcrumbs,
  Container,
  Link,
  makeStyles,
  Toolbar,
  Typography
} from "@material-ui/core";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import LoginButtonContainer from "../../auth/LoginButtonContainer";
import routes from "../../route/routes";
import MinisterDetailsFormContainer from "./MinisterDetailsFormContainer";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1)
  },
  grow: {
    flexGrow: 1
  },
  toolbar: theme.mixins.toolbar
}));

function MinisterCreatePage() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <AppBar>
        <Toolbar>
          <span className={classes.grow}>Admin</span>
          <LoginButtonContainer />
        </Toolbar>
      </AppBar>
      <Container maxWidth="md" className={classes.root}>
        <div className={classes.toolbar} />
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} color="inherit" to={routes.ADMIN}>
            Admin
          </Link>
          <Link
            component={RouterLink}
            color="inherit"
            to={routes.ADMIN_MINISTER_LIST}
          >
            Ministers
          </Link>
          <Typography color="textPrimary">Create Minister</Typography>
        </Breadcrumbs>
        <Typography variant="h4" component="h1" gutterBottom>
          Create Minister
        </Typography>

        <MinisterDetailsFormContainer />
      </Container>
    </React.Fragment>
  );
}

export default MinisterCreatePage;
