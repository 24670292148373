import {
    AppBar,
    Box,
    Breadcrumbs,
    Container,
    Link,
    makeStyles,
    Toolbar,
    Typography
  } from "@material-ui/core";
  import React from "react";
  import { Link as RouterLink } from "react-router-dom";
  import LoginButtonContainer from "../../auth/LoginButtonContainer";
  import OrphanedAudioFileListContainer from "./OrphanedAudioFileListContainer";
  

const useStyles = makeStyles(theme => ({
    root: {
      marginTop: theme.spacing(1)
    },
    grow: {
      flexGrow: 1
    },
    toolbar: theme.mixins.toolbar
  }));

  
function OrphanedAudioFileListPage() {
    const classes = useStyles();


    return (

        <React.Fragment>
        <AppBar>
          <Toolbar>
            <span className={classes.grow}>Webcaster Portal</span>
            <LoginButtonContainer />
          </Toolbar>
        </AppBar>
        <Container maxWidth="md" className={classes.root}>
          <div className={classes.toolbar} />
          <Breadcrumbs aria-label="breadcrumb">
            <Link component={RouterLink} color="inherit" to="/webcast">
            Webcast
            </Link>
            <Typography color="textPrimary">orphaned audio files</Typography>
          </Breadcrumbs>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Typography variant="h4" component="h1" gutterBottom>
              Orphaned Audio Files
            </Typography>
          </Box>
          <OrphanedAudioFileListContainer />
        </Container>
      </React.Fragment>
    )
}

export default OrphanedAudioFileListPage;

