import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { login, selectErrorMessage } from "../redux/modules/auth";
import LoginForm from "./LoginForm";

const mapStateToProps = state => ({
  errorMessage: selectErrorMessage(state)
});

const mapDispatchToProps = (dispatch, props) => ({
  login: formData => dispatch(login(formData, props.history))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LoginForm)
);
