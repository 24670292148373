import { AppBar, Button, makeStyles, Toolbar } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import React from "react";
import { Link } from "react-router-dom";
import LoginButtonContainer from "../auth/LoginButtonContainer";
import SermonDetailsContainer from "./SermonDetailsContainer";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2)
  },
  toolbar: theme.mixins.toolbar,
  grow: {
    flexGrow: 1
  },
  mt1: {
    marginTop: theme.spacing(1)
  }
}));

function SermonDetailPage() {
  const classes = useStyles();

  const sermonsLink = React.forwardRef((linkProps, ref) => (
    <Link to={"/sermons"} {...linkProps} innerRef={ref} />
  ));

  return (
    <React.Fragment>
      <AppBar>
        <Toolbar>
          <span className={classes.grow}>Sermon Details</span>
          <LoginButtonContainer />
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm" className={classes.root}>
        <div className={classes.toolbar} />

        <Button
          component={sermonsLink}
          variant="text"
          startIcon={<ChevronLeftIcon />}
        >
          Back to archive
        </Button>

        <Paper className={classes.mt1}>
          <SermonDetailsContainer />
        </Paper>
      </Container>
    </React.Fragment>
  );
}

export default SermonDetailPage;
