import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from "@material-ui/core";
import React from "react";

function SermonListLoadingEffect() {
  const numItems = 15;

  return (
    <List>
      {[...new Array(numItems)].map((_, idx) => (
        <React.Fragment key={idx.toString()}>
          <ListItem>
            <ListItemAvatar>
              <Avatar></Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <span className="loading-effect-container">
                  <span className="loading-effect-strip-1 loading-effect"></span>
                  <span className="loading-effect-strip-2 loading-effect"></span>
                </span>
              }
            ></ListItemText>
          </ListItem>
          {idx < numItems - 1 && <Divider component="li" variant="inset" />}
        </React.Fragment>
      ))}
    </List>
  );
}

export default SermonListLoadingEffect;
