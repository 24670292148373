const routes = {
  SERMON_LIST: "/sermons",
  SERMON_DETAILS: "/sermons/play/:sermonId",
  SERMON_EDIT: "/sermons/edit/:sermonId",
  SERMON_CREATE: "/sermons/create",
  SERMON_UPLOAD: "/sermons/upload",
  ADMIN: "/admin",
  ADMIN_USER_LIST: "/admin/users",
  ADMIN_USER_CREATE: "/admin/users/create",
  ADMIN_EDIT_USER_ROLES: "/admin/users/:userId/roles",
  ADMIN_MINISTER_LIST: "/admin/ministers",
  ADMIN_MINISTER_CREATE: "/admin/ministers/create",
  ADMIN_MINISTER_EDIT: "/admin/ministers/edit/:ministerId",
  ADMIN_CONGREGATION_LIST: "/admin/congregations",
  ADMIN_CONGREGATION_CREATE: "/admin/congregations/create",
  ADMIN_CONGREGATION_EDIT: "/admin/congregations/edit/:congregationId",
  ADMIN_EVENT_LIST: "/admin/events",
  ADMIN_EVENT_CREATE: "/admin/events/create",
  ADMIN_EVENT_EDIT: "/admin/events/edit/:eventId",  
  ADMIN_HOLIDAY_LIST: "/admin/holidays",
  ADMIN_HOLIDAY_CREATE: "/admin/holidays/create",
  ADMIN_HOLIDAY_EDIT: "/admin/holidays/edit/:holidayId",  
  WEBCAST: "/webcast",
  WEBCAST_UNPUBLISHED: "/webcast/unpublished",
  WEBCAST_ORPHANED: "/webcast/orphaned",
  LOGIN: "/login",
  CHANGE_PASSWORD: "/change_password",
  EMAIL_VERIFY: "/email/verify"
};

export default routes;
