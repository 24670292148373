import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  fetchAllCongregations,
  selectCongregations
} from "../../redux/modules/congregations";
import CongregationList from "./CongregationList";

function CongregationListContainer({ fetchAllCongregations, congregations }) {
  useEffect(() => {
    fetchAllCongregations();
  }, [fetchAllCongregations]);

  return <CongregationList congregations={congregations} />;
}

const mapStateToProps = state => ({
  congregations: selectCongregations(state)
});

const mapDipsatchToProps = dispatch => ({
  fetchAllCongregations: () => dispatch(fetchAllCongregations())
});

export default connect(
  mapStateToProps,
  mapDipsatchToProps
)(CongregationListContainer);
