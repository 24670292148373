import api from "../../api";
import httpClient from "../../http/http-client";
import { showSnackbar } from "./snackbar";

const handleAuthResp = auth => ({
  ...auth,
  expires_at: Date.now() + auth.expires_in * 1000
});

const LOGIN_SUCCESS = "llc-archive/auth/LOGIN_SUCCESS";
export const loginSuccess = auth => ({
  type: LOGIN_SUCCESS,
  auth
});

const INIT_LOAD_USER_SUCCESS = "llc-archive/auth/INIT_LOAD_USER_SUCCESS";
export const initUserLoadedSuccess = auth => ({
  type: INIT_LOAD_USER_SUCCESS,
  auth
});

const CLEAR_TOKENS = "llc-archive/auth/CLEAR_TOKENS";
export const clearTokens = () => ({
  type: CLEAR_TOKENS
});

const REFRESH_TOKEN_SUCCESS = "llc-archive/auth/REFRESH_TOKEN_SUCCESS";
export const refreshTokenSuccess = auth => ({
  type: REFRESH_TOKEN_SUCCESS,
  auth
});

export const login = (formData, history) => dispatch => {
  return httpClient
    .post(api.login, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(formData)
    })
    .then(resp => {
      if (resp.ok) {
        return resp.json();
      } else {
        return Promise.resolve(resp.json());
      }
    })
    .then(resp => {
      if (resp.message && resp.message === "Password Expired") {
        throw new Error(resp.message);
      }
      return resp;
    })
    .then(resp => handleAuthResp(resp))
    .then(resp => {
      localStorage.setItem("access_token", resp.access_token);
      localStorage.setItem("expires_at", resp.expires_at);
      localStorage.setItem("refresh_token", resp.refresh_token);
      localStorage.setItem("roles", JSON.stringify(resp.roles));
      localStorage.setItem("username", resp.username);

      dispatch(loginSuccess(resp));
      history.push("/sermons");
    })
    .catch(error => {
      dispatch(showSnackbar(error.message, "error"));
      if (error.message === "Password Expired") {
        history.push("/change_password");
      }
    });
};

export const logout = history => dispatch => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("expires_at");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("roles");
  localStorage.removeItem("username");

  dispatch(clearTokens());
  history.push("/login");
};

export const refreshToken = () => (dispatch, getState) => {
  const refresh_token = selectRefreshToken(getState());
  return httpClient
    .post(api.refreshAuth, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        grant_type: "refresh_token",
        refresh_token
      })
    })
    .then(resp => {
      if (resp.ok) {
        return resp.json();
      } else {
        throw new Error("Your session has expired. Please log in again.");
      }
    })
    .then(resp => handleAuthResp(resp))
    .then(resp => {
      localStorage.setItem("access_token", resp.access_token);
      localStorage.setItem("expires_at", resp.expires_at);
      localStorage.setItem("refresh_token", resp.refresh_token);

      dispatch(refreshTokenSuccess(resp));
    })
    .catch(error => {
      localStorage.removeItem("access_token");
      localStorage.removeItem("expires_at");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("roles");
      localStorage.removeItem("username");

      dispatch(clearTokens());
      dispatch(showSnackbar(error.message, "error"));
    });
};

export const initLoadUser = () => dispatch => {
  const auth = {
    access_token: localStorage.getItem("access_token"),
    expires_at: parseInt(localStorage.getItem("expires_at"), 10),
    refresh_token: localStorage.getItem("refresh_token"),
    roles: JSON.parse(localStorage.getItem("roles") || "[]"),
    username: localStorage.getItem("username")
  };
  dispatch(initUserLoadedSuccess(auth));
};

const initialState = {
  access_token: "",
  expires_at: null,
  refresh_token: "",
  roles: [],
  username: "",
  errorMessage: "",
  isLoaded: false
};

export const selectAccessToken = state => state.authState.access_token;

export const selectRefreshToken = state => state.authState.refresh_token;

export const selectExpiresAt = state => state.authState.expires_at;

export const selectUsername = state => state.authState.username;

export const selectRoles = state => state.authState.roles;

export const selectErrorMessage = state => state.authState.errorMessage;

export const selectIsLoggedIn = state =>
  state.authState.access_token && state.authState.access_token.length > 0;

export const selectIsLoaded = state => state.authState.isLoaded;

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOGIN_SUCCESS:
    case INIT_LOAD_USER_SUCCESS:
      return {
        ...action.auth,
        isLoaded: true
      };

    case CLEAR_TOKENS:
      return {
        ...initialState
      };

    case REFRESH_TOKEN_SUCCESS:
      return {
        ...initialState,
        access_token: action.auth.access_token,
        refresh_token: action.auth.refresh_token
      };

    default:
      return state;
  }
}
