import {
  AppBar,
  Breadcrumbs,
  Card,
  CardContent,
  Container,
  Grid,
  makeStyles,
  Toolbar,
  Typography
} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import MicIcon from "@material-ui/icons/Mic";
import PeopleIcon from "@material-ui/icons/People";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import LoginButtonContainer from "../auth/LoginButtonContainer";
import routes from "../route/routes";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1)
  },
  toolbar: theme.mixins.toolbar,
  grow: {
    flexGrow: 1
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  card: {
    textAlign: "center",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    color: theme.palette.grey
  },
  cardIcon: {
    fontSize: "3rem"
  }
}));

function CardLink({ to, ...rest }) {
  const classes = useStyles();

  const [hoverState, setHoverState] = useState(false);

  return (
    <Link underline="none" component={RouterLink} to={to}>
      <Card
        raised={hoverState}
        onMouseOver={() => setHoverState(true)}
        onMouseOut={() => setHoverState(false)}
      >
        <CardContent className={classes.card} {...rest} />
      </Card>
    </Link>
  );
}

function AdminPage({ history }) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <AppBar>
        <Toolbar>
          <span className={classes.grow}>Admin</span>
          <LoginButtonContainer />
        </Toolbar>
      </AppBar>
      <Container maxWidth="md" className={classes.root}>
        <div className={classes.toolbar} />

        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary">Admin</Typography>
        </Breadcrumbs>
        <Typography variant="h4" component="h1" gutterBottom>
          Admin
        </Typography>

        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <CardLink to="/webcast">
              <MicIcon className={classes.cardIcon} color="primary" />
              <Typography variant="h6" component="h2" color="primary">
                Sermons
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                Create, publish, manage files
              </Typography>
            </CardLink>
          </Grid>

          <Grid item sm={6} xs={12}>
            <CardLink to={routes.ADMIN_USER_LIST}>
              <PeopleIcon className={classes.cardIcon} color="primary" />
              <Typography variant="h6" component="h2" color="primary">
                Users
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                Manage users, assign roles
              </Typography>
            </CardLink>
          </Grid>

          <Grid item sm={6} xs={12}>
            <CardLink to={routes.ADMIN_MINISTER_LIST}>
              <RecordVoiceOverIcon
                className={classes.cardIcon}
                color="primary"
              />
              <Typography variant="h6" component="h2" color="primary">
                Ministers
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                Manage ministers
              </Typography>
            </CardLink>
          </Grid>

          <Grid item sm={6} xs={12}>
            <CardLink to={routes.ADMIN_CONGREGATION_LIST}>
              <HomeIcon className={classes.cardIcon} color="primary" />
              <Typography variant="h6" component="h2" color="primary">
                Congregations
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                Manage congregations
              </Typography>
            </CardLink>
          </Grid>

          <Grid item sm={6} xs={12}>
            <CardLink to={routes.ADMIN_EVENT_LIST}>
              <EventIcon
                className={classes.cardIcon}
                color="primary"
              />
              <Typography variant="h6" component="h2" color="primary">
              Events
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                Manage events
              </Typography>
            </CardLink>
          </Grid>

          <Grid item sm={6} xs={12}>
            <CardLink to={routes.ADMIN_HOLIDAY_LIST}>
              <ScheduleIcon
                className={classes.cardIcon}
                color="primary"
              />
              <Typography variant="h6" component="h2" color="primary">
              Church Holidays
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                Manage church holidays
              </Typography>
            </CardLink>
          </Grid>
        </Grid>





      </Container>
    </React.Fragment>
  );
}

export default AdminPage;
