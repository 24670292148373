import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import routes from "../../route/routes";

function MinisterList({ ministers }) {
  const rowsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  return (
    <Paper>
      {ministers.length ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Last Name</TableCell>
                <TableCell>First Name</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ministers
                .slice(
                  currentPage * rowsPerPage,
                  currentPage * rowsPerPage + rowsPerPage
                )
                .map((minister, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{minister.last_name}</TableCell>
                    <TableCell>{minister.first_name}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        size="small"
                        component={RouterLink}
                        to={routes.ADMIN_MINISTER_EDIT.replace(
                          ":ministerId",
                          minister.id
                        )}
                      >
                        <EditIcon color="primary" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[]}
                  page={currentPage}
                  count={ministers.length}
                  rowsPerPage={rowsPerPage}
                  onChangePage={handlePageChange}
                ></TablePagination>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      ) : (
        ""
      )}
    </Paper>
  );
}

export default MinisterList;
