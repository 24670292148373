import { useEffect } from "react";
import ReactGA from 'react-ga';
import { withRouter } from "react-router";

if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize('UA-92011312-3');
}

function GoogleAnalytics({ match, location, history }) {

    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            ReactGA.set({ page: location.pathname });
            ReactGA.pageview(location.pathname);
        }
    });

    return null;
}

export default withRouter(GoogleAnalytics);