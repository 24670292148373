import AppBar from "@material-ui/core/AppBar";
import grey from "@material-ui/core/colors/grey";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import queryString from "query-string";
import { default as React } from "react";
import { connect } from "react-redux";
import LoginButtonContainer from "../auth/LoginButtonContainer";
import CongregationMenuItemsContainer from "../congregation/CongregationMenuItemsContainer";
import {
  selectMobileDrawerOpen,
  toggleMobileDrawer
} from "../redux/modules/menu";
import SermonSearchContainer from "../sermon/SermonSearchContainer";
import SermonsContainer from "./SermonsContainer";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  menuButton: {
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  grow: {
    flexGrow: 1
  },
  title: {
    ...theme.mixins.toolbar,
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2)
  },
  sidebarTitle: {
    padding: theme.spacing(2, 2, 0, 2),
    fontWeight: "bold"
  },
  container: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      padding: 0
    }
  },
  toolbar: theme.mixins.toolbar,
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    },
    backgroundColor: grey[500]
  },
  drawerPaper: {
    width: drawerWidth
  }
}));

function SermonListPage({ history, mobileDrawerOpen, toggleMobileDrawer }) {
  const classes = useStyles();
  const theme = useTheme();
  const params = queryString.parse(history.location.search);

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            onClick={toggleMobileDrawer}
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>

          <div className={classes.grow}>
            <SermonSearchContainer />
          </div>
          <LoginButtonContainer />
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileDrawerOpen}
            onClose={toggleMobileDrawer}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true
            }}
          >
            <CongregationMenuItemsContainer />
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
          >
            <Typography variant="h6" className={classes.title}>
              LLC Sermon Archive
            </Typography>
            <Divider />
            <CongregationMenuItemsContainer />
          </Drawer>
        </Hidden>
      </nav>
      <Container maxWidth="sm" className={classes.container}>
        <div className={classes.toolbar} />
        <SermonsContainer params={params} />
      </Container>
    </div>
  );
}

const mapStateToProps = state => ({
  mobileDrawerOpen: selectMobileDrawerOpen(state)
});

const mapDipsatchToProps = dispatch => ({
  toggleMobileDrawer: () => dispatch(toggleMobileDrawer())
});

export default connect(mapStateToProps, mapDipsatchToProps)(SermonListPage);
