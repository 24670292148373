import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Select from "react-select";
import debounce from "../utils/debounce";
import SearchOption from "./SearchOption";

const useStyles = makeStyles(theme => ({
  search: {
    width: "100%",
    maxWidth: 500,
    [theme.breakpoints.up("sm")]: {
      width: "auto"
    },
    color: theme.palette.common.black
  }
}));

const Search = ({
  placeholder,
  options,
  selected,
  isLoading,
  onSearch,
  onSelect,
  noOptionsMessage
}) => {
  const classes = useStyles();

  const onInputChange = debounce(input => {
    onSearch(input);
  }, 300);

  return (
    <Select
      className={classes.search}
      onInputChange={onInputChange}
      value={selected}
      options={options}
      filterOption={false}
      getOptionLabel={option => option.field_label}
      getOptionValue={option => option.filter_name + ":" + option.field_value}
      isLoading={isLoading}
      onChange={onSelect}
      placeholder={placeholder}
      noOptionsMessage={noOptionsMessage}
      components={{ DropdownIndicator: () => null, Option: SearchOption }}
      isMulti={true}
    />
  );
};

export default Search;
