import {
  AppBar,
  Container,
  makeStyles,
  Toolbar,
  Typography
} from "@material-ui/core";
import React from "react";
import LoginButtonContainer from "../../auth/LoginButtonContainer";
import UserRolesFormContainer from "./UserRolesFormContainer";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4)
  },
  grow: {
    flexGrow: 1
  },
  toolbar: theme.mixins.toolbar
}));

function UserAssignRolesPage() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <AppBar>
        <Toolbar>
          <span className={classes.grow}>Assign Role</span>
          <LoginButtonContainer />
        </Toolbar>
      </AppBar>
      <Container maxWidth="md" className={classes.root}>
        <div className={classes.toolbar} />
        <Typography variant="h4" component="h1" gutterBottom>
          Assign Role
        </Typography>
        <UserRolesFormContainer />
      </Container>
    </React.Fragment>
  );
}

export default UserAssignRolesPage;
