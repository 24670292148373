import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AccountCircle from "@material-ui/icons/AccountCircle";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MicIcon from "@material-ui/icons/Mic";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import React from "react";
import { Link } from "react-router-dom";
import routes from "../route/routes";
import HasRole from "./HasRole";
import roles from "./roles";

const LoginButton = ({ isLoggedIn, logout, pathname }) => {
  const loginLink = React.forwardRef((linkProps, ref) => (
    <Link to={"/login"} {...linkProps} innerRef={ref} />
  ));

  const sermonArchiveLink = React.forwardRef((linkProps, ref) => (
    <Link to={"/sermons"} {...linkProps} innerRef={ref} />
  ));

  const adminLink = React.forwardRef((linkProps, ref) => (
    <Link to={"/admin"} {...linkProps} innerRef={ref} />
  ));
  const webcastLink = React.forwardRef((linkProps, ref) => (
    <Link to={"/webcast"} {...linkProps} innerRef={ref} />
  ));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return !isLoggedIn ? (
    <Button color="inherit" component={loginLink}>
      Login
    </Button>
  ) : (
    <React.Fragment>
      <IconButton
        color="inherit"
        aria-label="delete"
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <AccountCircle fontSize="large" />
      </IconButton>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          component={sermonArchiveLink}
          selected={pathname === routes.SERMON_LIST}
        >
          <ListItemIcon>
            <MicIcon />
          </ListItemIcon>
          <ListItemText>Sermon Archive</ListItemText>
        </MenuItem>
        <HasRole role={roles.CREATE_USER}>
          <MenuItem component={adminLink} selected={pathname === routes.ADMIN}>
            <ListItemIcon>
              <SupervisorAccountIcon />
            </ListItemIcon>
            <ListItemText>Admin</ListItemText>
          </MenuItem>
        </HasRole>
        <HasRole role={roles.CREATE_SERMON}>
          <MenuItem
            component={webcastLink}
            selected={pathname === routes.WEBCAST}
          >
            <ListItemIcon>
              <CloudUploadIcon />
            </ListItemIcon>
            <ListItemText>Webcaster Portal</ListItemText>
          </MenuItem>
        </HasRole>
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default LoginButton;
