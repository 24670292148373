import { useEffect } from "react";
import debounce from "../utils/debounce";

function InfiniteScroll({ loadMore, isLoading }) {
  useEffect(() => {
    window.addEventListener(
      "scroll",
      debounce(() => {
        if (!isLoading && isAtBottom()) {
          loadMore();
        }
      }, 200)
    );
  }, [isLoading, loadMore]);

  return null;
}

function isAtBottom() {
  const windowHeight =
    "innerHeight" in window
      ? window.innerHeight
      : document.documentElement.offsetHeight;
  const body = document.body;
  const html = document.documentElement;
  const docHeight = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  );
  const windowBottom = windowHeight + window.pageYOffset;
  return windowBottom >= docHeight;
}

export default InfiniteScroll;
