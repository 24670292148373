import queryString from "query-string";
import api from "../../api";
import httpClient from "../../http/http-client";
import routes from "../../route/routes";
import { showSnackbar } from "./snackbar";

const RECEIVE_SERMON_SUGGESTION =
  "llc-archive/sermon-form/RECEIVE_SERMON_SUGGESTION";
export const receiveSermonSuggestion = suggestion => ({
  type: RECEIVE_SERMON_SUGGESTION,
  suggestion
});


const REQUEST_UPLOAD = "llc-archive/sermon-form/REQUEST_UPLOAD";
const requestUpload = () => ({
  type: REQUEST_UPLOAD
});

const COMPLETED_UPLOAD = "llc-archive/event/COMPLETED_UPLOAD";
const completedUpload = () => ({
  type: COMPLETED_UPLOAD
});


export const fetchSermonSuggestion = audioFileId => dispatch => {
  return httpClient
    .get(api.sermonSuggestion(audioFileId))
    .then(resp => resp.json())
    .then(resp => dispatch(receiveSermonSuggestion(resp)));
};

export const saveForm = (formData, history) => dispatch => {
  const params = queryString.parse(history.location.search);

  if (params.audio_file_id) {
    formData = {
      ...formData,
      audio_file_id: parseInt(params.audio_file_id, 10)
    };
  }

  return httpClient
    .post(api.sermons, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(formData)
    })
    .then(resp => {
      if (resp.ok) {
        dispatch(showSnackbar("Sermon Created!"));
        return resp.json();
      } else {
        throw new Error("Failed to save the sermon");
      }
    })
    .then(resp => {
      history.push(routes.WEBCAST);
    })
    .catch(error => {
      dispatch(showSnackbar(error.message, "error"));
    });
};

export const upload = (file, history) => dispatch => {
  const data = new FormData();
  data.append("file", file);

  dispatch(requestUpload());
  return httpClient
    .post(api.audioFileUpload, {
      body: data
    })
    .then(resp => {
      if (resp.ok) {
        dispatch(showSnackbar("File uploaded!"));
      } else if (resp.status === 403) {
        throw new Error("You do not have permission to upload sermon files");
      }

      return resp.json();
    })
    .then(resp => {
      if (resp && resp.audio_file && resp.audio_file.id > 0) {
        history.push(
          `${routes.SERMON_CREATE}?audio_file_id=${resp.audio_file.id}`
        );
      } else if (resp && resp.code && resp.error) {
          throw new Error(resp.error);
      } else {
        throw new Error("Failed to upload sermon");
      }
    })
    .catch(error => {
      dispatch(showSnackbar(error.message, "error"));
    }).finally( () => {
      dispatch(completedUpload());
    });
};

export const cancelForm = history => dispatch => {
  history.push(routes.WEBCAST);
};

const initialState = {
  sermonSuggestion: undefined,
  isLoading: false
};

export const selectSermonSuggestion = state =>
  state.sermonFormState.sermonSuggestion;

export const selectIsLoading = state => state.sermonFormState.isLoading;

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case RECEIVE_SERMON_SUGGESTION:
      return {
        ...state,
        sermonSuggestion: action.suggestion
      };

    case REQUEST_UPLOAD:
      return {
        ...state,
        isLoading: true,
      };
  
    case COMPLETED_UPLOAD:
      return {
        ...state,
        isLoading: false,
      };
  

    default:
      return state;
  }
}
