import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import AsyncSelect from "react-select/async";
import api from "../api";
import httpClient from "../http/http-client";

const useStyles = makeStyles(theme => ({
  container: {
    position: "relative"
  },
  required: {
    height: 0,
    opacity: 0,
    width: "100%",
    position: "absolute",
    bottom: 0
  }
}));

const TypeaheadSelect = ({
  type,
  onChange,
  required,
  placeholder,
  isDisabled,
  label,
  value
}) => {
  const [state, setState] = useState(value);
  const classes = useStyles();

  useEffect(setState);

  const loadOptions = search =>
    httpClient
      .get(`${api.typeahead}?query=${search}&types=${type}`)
      .then(resp => resp.json());

  const noOptionsMessage = data => {
    return data.inputValue.length > 0
      ? "No options found"
      : "Start typing to search";
  };

  const handleChange = data => {
    setState(data.field_value);
    onChange(data);
  };

  return (
    <div className={classes.container}>
      <AsyncSelect
        loadOptions={loadOptions}
        getOptionLabel={option => option.field_label}
        getOptionValue={option => option.field_value}
        noOptionsMessage={noOptionsMessage}
        onChange={handleChange}
        isDisabled={isDisabled}
        placeholder={placeholder || ""}
        defaultValue={{ field_label: label, field_value: value }}
      />
      {/* Workaround until react-select provides a way to set required */}
      <input
        tabIndex={-1}
        autoComplete="off"
        className={classes.required}
        value={state}
        required={required}
        onChange={() => ""}
      />
    </div>
  );
};

export default TypeaheadSelect;
