import api from "../../api";
import httpClient from "../../http/http-client";
import routes from "../../route/routes";
import { showSnackbar } from "./snackbar";

const PASSWORD_CHANGE_SUCCESS = "llc-archive/password/PASSWORD_CHANGE_SUCCESS";
export const passwordChangeSuccess = passwordChange => ({
  type: PASSWORD_CHANGE_SUCCESS,
  passwordChange
});

export const changePassword = (formData, history) => dispatch => {
  return httpClient
    .post(api.changePassword, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(formData)
    })
    .then(resp => {
      if (resp.ok) {
        return resp.json();
      } else {
        throw new Error("password change failure");
      }
    })
    .then(resp => {
      dispatch(passwordChangeSuccess(resp));
      history.push("/login");
    })
    .catch(error => {
      dispatch(showSnackbar(error.message, "error"));
    });
};

export const cancelForm = history => dispatch => {
  history.push(routes.LOGIN);
};

const initialState = {
  isLoading: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {

    case PASSWORD_CHANGE_SUCCESS:
      return {
        ...state
      };

    default:
      return state;
  }
}
