const base_url = Object.is(process.env.REACT_APP_API_BASE_URL, undefined)
  ? "/api"
  : process.env.REACT_APP_API_BASE_URL;

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  login: `${base_url}/login`,
  logout: `${base_url}/logout`,
  refreshAuth: `${base_url}/oauth/access_token`,
  sermons: `${base_url}/sermons`,
  sermonSuggestion: audioFileId =>
    `${base_url}/audio_file/${audioFileId}/sermon_suggestion`,
  unpublishedSermons: `${base_url}/sermons/unpublished`,
  congregation: congregationId => `${base_url}/congregations/${congregationId}`,
  congregations: `${base_url}/congregations`,
  users: `${base_url}/users`,
  user: userId => `${base_url}/users/${userId}`,
  userRoles: userId => `${base_url}/users/${userId}/roles`,
  minister: ministerId => `${base_url}/ministers/${ministerId}`,
  ministers: `${base_url}/ministers`,
  event: eventId => `${base_url}/events/${eventId}`,
  events: `${base_url}/events`,
  holiday: holidayId => `${base_url}/holidays/${holidayId}`,
  holidays: `${base_url}/holidays`,
  roles: `${base_url}/roles/assignable`,
  audioFile: fileId => `${base_url}/audio_file/${fileId}`,
  audioFileUpload: `${base_url}/audio_file/upload`,
  audioFileOrphans: `${base_url}/audio_file/orphans`,
  typeahead: `${base_url}/typeahead`,
  changePassword: `${base_url}/users/change_password`,
  verifyEmail: `${base_url}/email/verify`
};
