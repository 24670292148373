import api from "../../api";
import httpClient from "../../http/http-client";
import routes from "../../route/routes";
import { showSnackbar } from "./snackbar";

const REQUEST_EVENTS = "llc-archive/event/REQUEST_EVENTS";
const requestEvents = () => ({
  type: REQUEST_EVENTS
});

const RECEIVE_EVENTS = "llc-archive/event/RECEIVE_EVENTS";
const receiveEvents = events => ({
  type: RECEIVE_EVENTS,
  events
});

const REQUEST_EVENT = "llc-archive/event/REQUEST_EVENT";
const requestEvent = () => ({
  type: REQUEST_EVENT
});

const RECEIVE_EVENT = "llc-archive/event/RECEIVE_EVENT";
const receiveEvent = event => ({
  type: RECEIVE_EVENT,
  event
});

export const fetchEvents = () => dispatch => {
  dispatch(requestEvents());
  return httpClient
    .get(api.events)
    .then(resp => resp.json())
    .then(resp => dispatch(receiveEvents(resp)));
};

export const fetchEvent = eventId => (dispatch, getState) => {
  const event = selectEvent(eventId, getState());
  if (event) {
    return;
  }
  dispatch(requestEvent());

  return httpClient
    .get(api.event(eventId))
    .then(resp => resp.json())
    .then(resp => dispatch(receiveEvent(resp)));
};

export const saveForm = (formData, history) => dispatch => {
  const editing = !!formData.id;
  const method = editing ? httpClient.put : httpClient.post;
  const url = editing ? api.event(formData.id) : api.events;

  return method(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(formData)
  })
    .then(resp => {
      if (resp.ok) {
        dispatch(showSnackbar("Event Saved!"));
        return resp.json();
      } else {
        const message =
          resp.status === 403
            ? "You don't have permission to edit events"
            : "Failed to save the event";
        console.log("resp", resp);
        throw new Error(message);
      }
    })
    .then(resp => {
      history.push(routes.ADMIN_EVENT_LIST);
    })
    .catch(error => {
      dispatch(showSnackbar(error.message, "error"));
    });
};

export const cancelForm = history => dispatch => {
  history.push(routes.ADMIN_EVENT_LIST);
};

const initialState = {
  events: [],
  isLoading: false
};

export const selectEvents = state => state.eventState.events;

export const selectEvent = (eventId, state) =>
  state.eventState.events.find(event => event.id === eventId);

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST_EVENTS:
      return {
        ...state,
        isLoading: true
      };

    case RECEIVE_EVENTS:
      return {
        ...state,
        isLoading: false,
        events: action.events
      };

    case RECEIVE_EVENT:
      return {
        ...state,
        events: [...state.events, action.event]
      };

    default:
      return state;
  }
}
