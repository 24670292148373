import api from "../../api";
import httpClient from "../../http/http-client";
import { showSnackbar } from "./snackbar";

const REQUEST_ROLES = "llc-archive/role/REQUEST_ROLES";
export const requestRoles = () => ({
  type: REQUEST_ROLES
});

export const RECIEVE_ROLES = "llc-archive/roles/RECEIVE_ROLES";
export const receiveRoles = roles => ({
  type: RECIEVE_ROLES,
  roles
});

/**
 * Takes a list of congregation roles like:
 * [
 *   {
 *      role: {...},
 *      congregation: {...}
 *   }
 * ]
 *
 * and returns a list of roles with the associated congregations:
 * [
 *    {
 *      // role details
 *      congregations: [...]
 *    }
 * ]
 *
 * @param congregationRoles
 */
const transformRoles = congregationRoles => {
  const roleMap = congregationRoles.reduce((acc, congregationRole) => {
    const { congregation, role } = congregationRole;
    if (!acc[role.id]) {
      acc[role.id] = {
        ...role,
        congregations: []
      };
    }

    if (congregation) {
      acc[role.id].congregations = [
        ...acc[role.id].congregations,
        congregation
      ];
    }

    return acc;
  }, {});

  return Object.values(roleMap);
};

export const fetchRoles = () => (dispatch, getState) => {
  const state = getState();
  if (selectIsLoading(state) || selectRoles(state).length) {
    return;
  }

  dispatch(requestRoles());
  return httpClient
    .get(`${api.roles}`)
    .then(resp => {
      if (resp.ok) {
        return resp.json();
      } else {
        throw new Error("Failed to retrieve roles");
      }
    })
    .then(congregationRoles => transformRoles(congregationRoles))
    .then(roles => dispatch(receiveRoles(roles)))
    .catch(error => {
      dispatch(showSnackbar(error.message, "error"));
    });
};

const initialState = {
  roles: [],
  isLoading: false
};

export const selectRoles = state => state.roleState.roles;

export const selectIsLoading = state => state.roleState.isLoading;

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST_ROLES:
      return {
        ...state,
        isLoading: true
      };
    case RECIEVE_ROLES:
      return {
        ...state,
        roles: action.roles
      };

    default:
      return state;
  }
}
