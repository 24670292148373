import queryString from "query-string";
import api from "../../api";
import httpClient from "../../http/http-client";
import routes from "../../route/routes";
import { showSnackbar } from "../modules/snackbar";

const REQUEST = "llc-archive/congregation/REQUEST";
export const requestCongregations = () => ({
  type: REQUEST
});

const RECEIVE = "llc-archive/congregation/RECEIVE";
export const receiveCongregations = congregations => ({
  type: RECEIVE,
  congregations
});


const REQUEST_ALL = "llc-archive/congregation/REQUEST_ALL";
export const requestAllCongregations = () => ({
  type: REQUEST_ALL
});

const RECEIVE_ALL = "llc-archive/congregation/RECEIVE_ALL";
export const receiveAllCongregations = congregations => ({
  type: RECEIVE_ALL,
  congregations
});


const REQUEST_CONGREGATION = "llc-archive/congregation/REQUEST_CONGREGATION";
export const requestCongregation = () => ({
  type: REQUEST_CONGREGATION
});

const RECEIVE_CONGREGATION = "llc-archive/congregation/RECEIVE_CONGREGATION";
export const receiveCongregation = congregation => ({
  type: RECEIVE_CONGREGATION,
  congregation
});

export const CONGREGATION_SELECTED =
  "llc-archive/congregation/CONGREGATION_SELECTED";
export const congregationSelected = congregationId => ({
  type: CONGREGATION_SELECTED,
  congregationId
});

const CONGREGATION_SAVED = "llc-archive/congregation/CONGREGATION_SAVED";
const congregationSaved = congregation => ({
  type: CONGREGATION_SAVED,
  congregation
});

const CONGREGATION_DISABLED = "llc-archive/congregation/CONGREGATION_DISABLED";
const congregationDisabled = congregation => ({
  type: CONGREGATION_DISABLED,
  congregation
});

const CONGREGATION_ENABLED = "llc-archive/congregation/CONGREGATION_ENABLED";
const congregationEnabled = congregation => ({
  type: CONGREGATION_ENABLED,
  congregation
});

export const fetchCongregations = () => (dispatch) => {
  dispatch(requestCongregations());

  return httpClient
    .get(api.congregations)
    .then(resp => resp.json())
    .then(resp => dispatch(receiveCongregations(resp)));
};

export const fetchAllCongregations = () => (dispatch) => {
  dispatch(requestAllCongregations());

  return httpClient
    .get(`${api.congregations}/all`)
    .then(resp => resp.json())
    .then(resp => dispatch(receiveAllCongregations(resp)));
};

export const fetchCongregation = congregationId => (dispatch, getState) => {
  const congregation = selectCongregation(congregationId, getState());
  if (congregation) {
    return;
  }

  dispatch(requestCongregation());

  return httpClient
    .get(api.congregation(congregationId))
    .then(resp => resp.json())
    .then(resp => dispatch(receiveCongregation(resp)));
};

export const goToCongregation = (congregationId, router) => dispatch => {
  const params = queryString.parse(router.location.search);
  params.congregation_id = congregationId;

  const query = queryString.stringify(params);
  router.push(`/sermons?${query}`);
  dispatch(congregationSelected(congregationId));
};

const initialState = {
  congregations: []
};

export const selectCongregations = state =>
  state.congregationState.congregations || [];

export const selectCongregation = (congregationId, state) =>
  state.congregationState.congregations.find(
    congregation => congregation.id === congregationId
  );

export const saveForm = (formData, history) => dispatch => {
  const editing = !!formData.id;
  const method = editing ? httpClient.put : httpClient.post;
  const url = editing ? api.congregation(formData.id) : api.congregations;

  return method(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(formData)
  })
    .then(resp => {
      if (resp.ok) {
        dispatch(showSnackbar("Congregation Saved!"));
        return resp.json();
      } else {
        const message =
          resp.status === 403
            ? "You don't have permission to edit congregations"
            : "Failed to save the congregation";
        console.log("resp", resp);
        throw new Error(message);
      }
    })
    .then(resp => {
      dispatch(congregationSaved(resp));
      history.push(routes.ADMIN_CONGREGATION_LIST);
    })
    .catch(error => {
      dispatch(showSnackbar(error.message, "error"));
    });
};

export const cancelForm = history => dispatch => {
  history.push(routes.ADMIN_CONGREGATION_LIST);
};

export const enableCongregation = (congregation, history) => dispatch => {
  return httpClient
    .post(`${api.congregations}/${congregation.id}/enable`)
    .then(resp => resp.json())
    .then(resp => {

      if (resp.message) {
        dispatch(showSnackbar(resp.message, "error"));
      }
      else {
        dispatch(showSnackbar("Congregation Enabled"));
        dispatch(congregationEnabled(resp));
        history.push(routes.ADMIN_CONGREGATION_LIST);
      }

    })
    .catch(error => {
      dispatch(showSnackbar(error, "error"));
    });
};


export const disableCongregation = (congregation, history) => dispatch => {
  return httpClient
    .post(`${api.congregations}/${congregation.id}/disable`)
    .then(resp => resp.json())
    .then(resp => {

      if (resp.message) {
        dispatch(showSnackbar(resp.message, "error"));
      }
      else {
        dispatch(showSnackbar("Congregation Disabled"));
        dispatch(congregationDisabled(resp));
        history.push(routes.ADMIN_CONGREGATION_LIST);
      }

    })
    .catch(error => {
      dispatch(showSnackbar(error, "error"));
    });
};


export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case RECEIVE:
    case RECEIVE_ALL:
      return {
        ...state,
        congregations: action.congregations
      };

    case RECEIVE_CONGREGATION:
      return {
        ...state,
        congregations: [...state.congregations, action.congregation]
      };

    case CONGREGATION_DISABLED:
    case CONGREGATION_ENABLED:
    case CONGREGATION_SAVED:
      const idx = state.congregations.findIndex(
        congregation => congregation.id === action.congregation.id
      );
      if (idx > -1) {
        return {
          ...state,
          congregations: [
            ...state.congregations.slice(0, idx),
            action.congregation,
            ...state.congregations.slice(idx + 1)
          ]
        };
      } else {
        return {
          ...state,
          congregations: [...state.congregations, action.congregation]
        };
      }

    default:
      return state;
  }
}
