import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { logout, selectIsLoggedIn } from "../redux/modules/auth";
import LoginButton from "./LoginButton";

const mapStateToProps = (state, props) => ({
  isLoggedIn: selectIsLoggedIn(state),
  pathname: props.history.location.pathname
});

const mapDispatchToProps = (dispatch, props) => ({
  logout: () => dispatch(logout(props.history))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginButton)
);
