import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  fetchEvents,
  selectEvents
} from "../../redux/modules/events";
import EventList from "./EventList";

function EventListContainer({ fetchEvents, events }) {
  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  return <EventList events={events} />;
}

const mapStateToProps = state => ({
  events: selectEvents(state)
});

const mapDipsatchToProps = dispatch => ({
  fetchEvents: () => dispatch(fetchEvents())
});

export default connect(
  mapStateToProps,
  mapDipsatchToProps
)(EventListContainer);
