import React from "react";

const BibleReference = ({ reference }) => {
  return (
    <span>
      {reference.name} {reference.start_chapter}
      {reference.end_chapter !== reference.start_chapter
        ? `-${reference.end_chapter}`
        : ""}
      :{reference.start_verse}
      {reference.end_verse !== reference.start_verse
        ? `-${reference.end_verse}`
        : ""}
    </span>
  );
};

export default BibleReference;
