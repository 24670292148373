import {
  Button,
  DialogContent,
  DialogContentText,
  makeStyles,
  Paper
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import Sermons from "../../archive/Sermons";

const useStyles = makeStyles(theme => ({
  noData: {
    textAlign: "center",
    fontSize: theme.typography.h4.fontSize,
    color: theme.palette.grey[500]
  }
}));

function UnpublishedSermonList({ sermons, publishSermon }) {
  const classes = useStyles();
  const [sermonToPublish, setSermonToPublish] = React.useState(null);

  const confirmPublish = () => {
    setSermonToPublish(null);
    publishSermon(sermonToPublish);
  };

  const cancelPublish = () => {
    setSermonToPublish(null);
  };

  return (
    <React.Fragment>
      {sermons && sermons.length ? (
        <Paper>
          <Sermons
            sermons={sermons}
            publishSermon={setSermonToPublish}
          ></Sermons>
        </Paper>
      ) : (
        <div className={classes.noData}>No Unpublished Sermons</div>
      )}
      <Dialog
        open={!!sermonToPublish}
        onClose={cancelPublish}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Publish Sermon?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This sermon will be visible to the public.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelPublish}>Cancel</Button>
          <Button onClick={confirmPublish} color="primary" autoFocus>
            Publish
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default UnpublishedSermonList;
