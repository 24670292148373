import queryString from "query-string";
import api from "../../api";
import httpClient from "../../http/http-client";
import { showSnackbar } from "./snackbar";

const REQUEST_SERMONS = "llc-archive/sermon/REQUEST_SERMONS";
export const requestSermons = () => ({
  type: REQUEST_SERMONS
});

export const RECEIVE_SERMONS = "llc-archive/sermon/RECEIVE_SERMONS";
export const receiveSermons = (sermons, meta) => ({
  type: RECEIVE_SERMONS,
  sermons,
  meta
});

const REQUEST_MORE = "llc-archive/sermon/REQUEST_MORE";
export const requestMoreSermons = () => ({
  type: REQUEST_MORE
});

const RECEIVE_MORE = "llc-archive/sermon/RECEIVE_MORE";
export const receiveMoreSermons = (sermons, meta) => ({
  type: RECEIVE_MORE,
  sermons,
  meta
});

const REQUEST_SERMON = "llc-archive/sermon/REQUEST_SERMON";
export const requestSermon = sermonId => ({
  type: REQUEST_SERMON,
  sermonId
});

export const RECEIVE_SERMON = "llc-archive/sermon/RECEIVE_SERMON";
export const receiveSermon = sermon => ({
  type: RECEIVE_SERMON,
  sermon
});

const UNPUBLISHED = "llc-archive/unpublished-sermons/UNPUBLISHED";
const sermonUnpublished = sermon => ({
  type: UNPUBLISHED,
  sermon
});

const DELETED = "llc-archive/sermon/DELETED";
const sermonDeleted = () => ({
  type: DELETED
});

const buildQuery = params => {
  if (parseInt(params.congregation_id) === 0) {
    delete params.congregation_id;
  }

  return queryString.stringify(params);
};

export const fetchSermons = history => dispatch => {
  let params = queryString.parse(history.location.search);
  dispatch(requestSermons());
  return httpClient
    .get(`${api.sermons}?${buildQuery(params)}`)
    .then(resp => resp.json())
    .then(resp => {
      // Delay on initial load to prevent flicker of loading effect
      setTimeout(() => {
        dispatch(receiveSermons(resp.sermons, resp.meta));
      }, 300);
    });
};

export const loadMoreSermons = history => (dispatch, getState) => {
  let params = queryString.parse(history.location.search);
  const state = getState();
  const isLastPage = selectIsLastPage(state);
  const isLoading = selectIsLoading(state);
  const meta = selectMeta(state);

  if (!isLastPage && !isLoading) {
    params = {
      ...params,
      page_number: meta.page.page_number + 1
    };
    dispatch(requestMoreSermons());
    return httpClient
      .get(`${api.sermons}?${buildQuery(params)}`)
      .then(resp => resp.json())
      .then(resp => dispatch(receiveMoreSermons(resp.sermons, resp.meta)));
  }
};

export const fetchSermon = sermonId => (dispatch, getState) => {
  const state = getState();
  const sermon = selectSermon(sermonId, state);
  if (sermon) {
    return;
  }

  dispatch(requestSermon());
  return httpClient
    .get(`${api.sermons}/${sermonId}`)
    .then(resp => resp.json())
    .then(resp => dispatch(receiveSermon(resp)));
};

export const unpublishSermon = sermon => dispatch => {
  return httpClient
    .post(`${api.sermons}/${sermon.id}/unpublish`)
    .then(resp => resp.json())
    .then(resp => {

      if (resp.message) {
        dispatch(showSnackbar(resp.message, "error"));
      }
      else {
        dispatch(showSnackbar("Sermon Unpublished"));
        dispatch(sermonUnpublished(resp));
      }

    })
    .catch(error => {
      dispatch(showSnackbar(error, "error"));
    });
};

export const deleteSermon = sermon => dispatch => {
  return httpClient
    .del(`${api.sermons}/${sermon.id}`)
    .then(resp => {
      if (resp.status === 204) {
        dispatch(showSnackbar("Sermon Deleted"));
        dispatch(sermonDeleted());
      }
      else {
        Promise.resolve(resp.json()).then(resp => {
          dispatch(showSnackbar(resp.message, "error"));
        })
      }
    })
    .catch(error => {
      dispatch(showSnackbar(error, "error"));
    });
};

const initialState = {
  sermons: [],
  meta: {},
  isLoading: false
};

export const selectSermons = state => state.sermonState.sermons;

export const selectSermon = (sermonId, state) =>
  state.sermonState.sermons.find(sermon => sermon.id === sermonId);

export const selectMeta = state => state.sermonState.meta;

export const selectIsLoading = state => state.sermonState.isLoading;

export const selectIsLastPage = state =>
  state.sermonState.meta.page &&
  (state.sermonState.meta.page.page_number || 0) ===
    state.sermonState.meta.page.total_pages - 1;

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST_SERMONS:
      return {
        ...state,
        isLoading: true,
        sermons: []
      };

    case REQUEST_MORE:
    case REQUEST_SERMON:
      return {
        ...state,
        isLoading: true
      };
    case RECEIVE_SERMONS:
      return {
        ...state,
        sermons: action.sermons,
        meta: action.meta,
        isLoading: false
      };

    case RECEIVE_MORE:
      return {
        ...state,
        sermons: [...state.sermons, ...action.sermons],
        meta: action.meta,
        isLoading: false
      };

    case RECEIVE_SERMON:
      return {
        ...state,
        sermons: [...state.sermons, action.sermon],
        isLoading: false
      };

    case UNPUBLISHED:
      return {
        ...state,
        sermons: state.sermons.filter(
          sermon => sermon.id !== action.sermon.id
        )
      };

    case DELETED:
      return {
        ...state,
        sermons: []
      };
  
    default:
      return state;
  }
}
