import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles(theme => ({
  option: {
    display: "flex",
    flexDirection: "row"
  },
  optionKey: {
    color: "gray",
    width: 100,
    textAlign: "right",
    marginRight: 10,
    fontStyle: "italic"
  },
  optionLabel: {
    flex: 1
  }
}));

const SearchOption = props => {
  const classes = useStyles();

  return (
    <div {...props.innerProps} style={props.getStyles("option", props)}>
      <div className={classes.option}>
        <div className={classes.optionKey}>{props.data.type_label}:</div>
        <div className={classes.optionLabel}>{props.data.field_label}</div>
      </div>
    </div>
  );
};

export default SearchOption;
