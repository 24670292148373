import queryString from "query-string";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { selectFile } from "../../redux/modules/audio-files";
import {
  cancelForm,
  saveForm,
  selectSermonSuggestion
} from "../../redux/modules/sermon-form";
import SermonFormDetails from "./SermonFormDetails";

const extractFileId = props => {
  const params = queryString.parse(props.location.search);
  return Number.parseInt(params.audio_file_id, 10);
};

const mapStateToProps = (state, props) => ({
  sermonSuggestion: selectSermonSuggestion(state),
  file: selectFile(extractFileId(props), state)
});

const mapDispatchToProps = (dispatch, props) => ({
  saveForm: formData => dispatch(saveForm(formData, props.history)),
  cancel: () => dispatch(cancelForm(props.history))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SermonFormDetails)
);
