import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  cancelForm,
  saveForm,
  selectCongregation,
  enableCongregation,
  disableCongregation
} from "../../redux/modules/congregations";
import CongregationDetailsForm from "./CongregationDetailsForm";

const CongregationDetailsFormContainer = ({
  congregation,
  saveForm,
  cancel,
  enable,
  disable
}) => {
  return (
    <CongregationDetailsForm
      congregation={congregation}
      saveForm={saveForm}
      cancel={cancel}
      enable={enable}
      disable={disable}
    />
  );
};

const getCongregationIdParam = props =>
  Number.parseInt(props.match.params.congregationId, 10);

const mapStateToProps = (state, props) => ({
  congregation: selectCongregation(getCongregationIdParam(props), state)
});

const mapDispatchToProps = (dispatch, props) => ({
  saveForm: formData => dispatch(saveForm(formData, props.history)),
  cancel: () => dispatch(cancelForm(props.history)),
  enable: congregation => dispatch(enableCongregation(congregation, props.history)),
  disable: congregation => dispatch(disableCongregation(congregation, props.history))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CongregationDetailsFormContainer)
);
