import { makeStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LoadingIndicator from "../common/LoadingIndicator";
import {
  fetchSermon,
  selectIsLoading,
  selectSermon
} from "../redux/modules/sermons";
import {
  cancelForm,
  saveForm
} from "../redux/modules/sermon-edit-form";
import SermonEdit from "./SermonEdit";

const useStyles = makeStyles(theme => ({
  container: {
    position: "relative"
  },
  loading: {
    top: theme.spacing(2),
    right: theme.spacing(2),
    position: "absolute"
  },
  noData: {
    textAlign: "center",
    fontSize: theme.typography.h4.fontSize,
    color: theme.palette.grey[500]
  }
}));

function SermonEditContainer({ sermonId, sermon, fetchSermon, isLoading, saveForm, cancel }) {
  const classes = useStyles();

  useEffect(() => {
    fetchSermon(sermonId);
  }, [fetchSermon, sermonId]);

  let content;
  if (sermon && sermon.id) {
    content = (
      <Paper className={classes.container}>
        <SermonEdit sermon={sermon} saveForm={saveForm} cancel={cancel}></SermonEdit>
      </Paper>
    );
  } else {
    content = <div className={classes.noData}>No sermon found</div>;
  }

  return (
    <React.Fragment>
      <div className={classes.container}>
        {content}
        <div className={classes.loading}>
          <LoadingIndicator visible={isLoading}></LoadingIndicator>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state, props) => ({
  sermonId: Number.parseInt(props.match.params.sermonId, 10),
  sermon: selectSermon(Number.parseInt(props.match.params.sermonId, 10), state),
  isLoading: selectIsLoading(state)
});

const mapDispatchToProps = (dispatch, props) => ({
  fetchSermon: params => dispatch(fetchSermon(params)),
  saveForm: (sermon, formData) => dispatch(saveForm(sermon, formData, props.history)),
  cancel: (sermon) => dispatch(cancelForm(sermon, props.history))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SermonEditContainer)
);
