import DateFnsUtils from "@date-io/date-fns";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import React from "react";
import BibleReference from "../../bible/bibleReference";
import BibleReferencePicker from "../../bible/bibleReferencePicker";
import TypeaheadSelect from "../../common/TypeaheadSelect";

const useStyles = makeStyles(theme => ({
  form: {
    display: "flex",
    flexDirection: "column"
  },
  formGroup: {
    marginTop: theme.spacing(2)
  },
  label: {
    marginBottom: theme.spacing(1),
    display: "block"
  },
  datePicker: {
    marginTop: 0
  },
  title: {
    margin: 0
  },
  content: {
    padding: theme.spacing(2),
    borderTopStyle: "dashed",
    borderTopWidth: 1,
    borderTopColor: theme.palette.grey[500]
  },

  footer: {
    display: "flex",
    flexDirection: "row",
    borderTopStyle: "dashed",
    borderTopWidth: 1,
    borderTopColor: theme.palette.grey[500],
    padding: theme.spacing(2)
  },
  footerButton: {
    flex: 1,
    marginRight: theme.spacing(1)
  },
  audio_info: {
    marginBottom: theme.spacing(1),
    display: "block"
  },
  audio_label: {
    marginRight:  theme.spacing(1),
    fontWeight: "bold"
  }

}));

function SermonFormDetails({ saveForm, cancel, sermonSuggestion, file }) {
  const classes = useStyles();
  const today = new Date();

  const [
    isBibleReferencePickerOpen,
    setBibleReferencePickerOpen
  ] = React.useState(false);

  const [formData, setFormData] = React.useState({
    bible_references: [],
    spoken_timestamp: null
  });

  const [cancelDialogOpen, setCancelDialogOpen] = React.useState(false);

  const handleSubmit = event => {
    event.preventDefault();
    saveForm(formData);
  };

  const handleDateChange = date => {
    setFormData({ ...formData, spoken_timestamp: date });
  };

  const typeaheadChange = data => {
    setFormData({ ...formData, [data.filter_name]: data.field_value });
  };

  const bibleReferenceSelect = bibleReference => {
    setFormData({
      ...formData,
      bible_references: [
        ...formData.bible_references,
        {
          book_id: bibleReference.book.book_id,
          name: bibleReference.book.name,
          start_chapter: bibleReference.chapter,
          end_chapter: bibleReference.chapter,
          start_verse: bibleReference.start_verse,
          end_verse: bibleReference.end_verse
        }
      ]
    });
  };

  const bibleReferenceRemove = bibleReference => {
    const bible_references = [...formData.bible_references];
    const idx = bible_references.indexOf(bibleReference);
    bible_references.splice(idx, 1);

    setFormData({
      ...formData,
      bible_references
    });
  };

  return (
    <React.Fragment>
      <form className={classes.form} onSubmit={handleSubmit}>
        <Stepper activeStep={1}>
          <Step>
            <StepLabel>Upload file</StepLabel>
          </Step>
          <Step>
            <StepLabel>Enter sermon details</StepLabel>
          </Step>
        </Stepper>
        <div className={classes.content}>
          {file && (

            <fieldset>
              <legend>MP3 File Tags</legend>

              <div className={classes.audio_info}>
                <label className={classes.audio_label} htmlFor="file_name">File Name:</label>
                <span className={classes.audio_field} id="file_name" name="file_name">{file.file_name}</span>
              </div>

              <div className={classes.audio_info}>
                <label className={classes.audio_label} htmlFor="artist">Artist:</label>
                <span className={classes.audio_field}  id="artist" name="artist">{file.artist}</span>
              </div>

              <div className={classes.audio_info}>
                <label className={classes.audio_label} htmlFor="album">Album:</label>
                <span className={classes.audio_field}  id="album" name="album">{file.album}</span>
              </div>

              <div className={classes.audio_info}>
                <label className={classes.audio_label} htmlFor="comment">Comment:</label>
                <span className={classes.audio_field}  id="comment" name="comment">{file.comment}</span>
              </div>

              <div className={classes.audio_info}>
                <label className={classes.audio_label} htmlFor="title">Title:</label>
                <span className={classes.audio_field}  id="title" name="title">{file.title}</span>
              </div>

              {file.uploaded_by && file.uploaded_by.first_name && file.uploaded_by.last_name && (
                <div className={classes.audio_info}>
                  <label className={classes.audio_label} htmlFor="uploaded_by">Uploaded By:</label>
                  <span className={classes.audio_field}  id="uploaded_by" name="uploaded_by">{file.uploaded_by.first_name + " " + file.uploaded_by.last_name}</span>
                </div>
              )}

            </fieldset>
          )}
          <div className={classes.formGroup}>
            <label className={classes.label}>Spoken Date</label>
            {sermonSuggestion && sermonSuggestion.spoken_timestamp ? (
                          <FormHelperText>
                            Maybe: {sermonSuggestion.spoken_timestamp}
                          </FormHelperText>
                        ) : (
                          ""
                        )}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className={classes.datePicker}
                disableToolbar
                maxDate={today}
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                value={formData.spoken_timestamp}
                onChange={handleDateChange}
                required={true}
              />
            </MuiPickersUtilsProvider>
          </div>

          <div className={classes.formGroup}>
            <label className={classes.label}>Congregation</label>
            {sermonSuggestion && sermonSuggestion.congregation ? (
                          <FormHelperText>
                            Maybe: {sermonSuggestion.congregation.name}
                          </FormHelperText>
                        ) : (
                          ""
                        )}
            <TypeaheadSelect
              type="congregation"
              onChange={typeaheadChange}
              required={true}
            />
          </div>

          <div className={classes.formGroup}>
            <label className={classes.label}>Minister</label>
            {sermonSuggestion && sermonSuggestion.minister ? (
                          <FormHelperText>
                            Maybe: {sermonSuggestion.minister.first_name}{" "}
                            {sermonSuggestion.minister.last_name}
                          </FormHelperText>
                        ) : (
                          ""
                        )}
            <TypeaheadSelect
              type="minister"
              onChange={typeaheadChange}
              required={true}
            />
          </div>

          <div className={classes.formGroup}>
            <label className={classes.label}>Event</label>
            {sermonSuggestion && sermonSuggestion.event ? (
                          <FormHelperText>
                            Maybe: {sermonSuggestion.event.name}
                          </FormHelperText>
                        ) : (
                          ""
                        )}
            <TypeaheadSelect
              type="event"
              onChange={typeaheadChange}
              required={false}
            />
          </div>

          <div className={classes.formGroup}>
            <label className={classes.label}>Church Holiday</label>
            {sermonSuggestion && sermonSuggestion.holiday ? (
                          <FormHelperText>
                            Maybe: {sermonSuggestion.holiday.name + " : " + sermonSuggestion.holiday.theme}
                          </FormHelperText>
                        ) : (
                          ""
                        )}
            <TypeaheadSelect
              type="holiday"
              onChange={typeaheadChange}
              required={false}
            />
          </div>

          <div className={classes.formGroup}>
            <label className={classes.label}>Bible References</label>
            {sermonSuggestion &&
            sermonSuggestion.bible_references &&
            sermonSuggestion.bible_references.length ? (
              <FormHelperText>
                Maybe:{" "}
                {sermonSuggestion.bible_references.map((reference, key) => (
                  <BibleReference key={key} reference={reference} />
                ))}
              </FormHelperText>
            ) : (
              ""
            )}
            <List disablePadding>
              {formData.bible_references.map((reference, idx) => (
                <ListItem key={idx}>
                  <ListItemIcon>
                    <IconButton
                      edge="start"
                      aria-label="delete"
                      onClick={() => bibleReferenceRemove(reference)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemIcon>
                  <ListItemText
                    primary={<BibleReference reference={reference} />}
                  />
                </ListItem>
              ))}
            </List>
            <div>
              <Button
                variant="outlined"
                type="button"
                onClick={() => setBibleReferencePickerOpen(true)}
              >
                Add Bible Reference
              </Button>
            </div>
          </div>

          <BibleReferencePicker
            open={isBibleReferencePickerOpen}
            onClose={() => setBibleReferencePickerOpen(false)}
            onSelect={reference => bibleReferenceSelect(reference)}
          />
        </div>

        <div className={classes.footer}>
          <Button
            className={classes.footerButton}
            variant="contained"
            color="primary"
            type="submit"
          >
            Save
          </Button>

          <Button
            className={classes.footerButton}
            type="button"
            onClick={() => setCancelDialogOpen(true)}
          >
            Cancel
          </Button>
        </div>
      </form>
      <Dialog
        open={cancelDialogOpen}
        onClose={() => setCancelDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Discard Changes?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sermon details will be discarded. Your uploaded file can be found
            under "Orphaned Files".
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCancelDialogOpen(false)}>Cancel</Button>
          <Button color="secondary" onClick={cancel}>
            Discard
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default SermonFormDetails;
