import { connect } from "react-redux";
import { selectIsLoggedIn, selectRoles } from "../redux/modules/auth";

const HasRole = ({ children, role, isLoggedIn, userRoles }) => {
  const hasRole = isLoggedIn && !!userRoles.find(r => r.indexOf(role) > -1);
  return hasRole ? children : "";
};

const mapStateToProps = state => ({
  isLoggedIn: selectIsLoggedIn(state),
  userRoles: selectRoles(state)
});

export default connect(mapStateToProps, null)(HasRole);
