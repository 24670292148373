import { Box, IconButton, ListItemSecondaryAction } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PublishIcon from "@material-ui/icons/Publish";
import React from "react";
import BibleReference from "../bible/bibleReference";
import MinisterAvatar from "../minister/MinisterAvatar";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: "inline"
  }
}));

function Sermons({ sermons, publishSermon, select }) {
  const classes = useStyles();

  function formatDate(date) {
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long",
      day: "2-digit"
    }).format(new Date(date))
  }

  return (
    <List className={classes.root}>
      {sermons.map((sermon, index) => (
        <React.Fragment key={index.toString()}>
          <ListItem
            button={sermon.published}
            onClick={() => (select ? select(sermon) : null)}
            alignItems="flex-start"
          >
            <ListItemAvatar>
              <MinisterAvatar minister={sermon.minister}></MinisterAvatar>
            </ListItemAvatar>
            <ListItemText
              primary={`${sermon.minister.first_name} ${sermon.minister.last_name}`}
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                  >
                    {formatDate(sermon.spoken_timestamp)}
                  </Typography>
                  <Box paddingX={1} component="span">
                    <span>&#8226;</span>
                  </Box>
                  {sermon.bible_references.map((reference, index) => (
                    <React.Fragment key={index.toString()}>
                      {index !== 0 ? ", " : ""}
                      <BibleReference reference={reference} />
                    </React.Fragment>
                  ))}

                  {sermon.event && sermon.event.name ? (
                  <React.Fragment>
                    <Box paddingX={1} component="span">
                      <span>&#8226;</span>
                    </Box>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      {sermon.event.name}
                    </Typography>
                  </React.Fragment>
                  ) : (
                    ""
                  )}


                  {sermon.holiday && sermon.holiday.name ? (
                  <React.Fragment>
                    <Box paddingX={1} component="span">
                      <span>&#8226;</span>
                    </Box>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      {sermon.holiday.name}
                    </Typography>
                  </React.Fragment>
                  ) : (
                    ""
                  )}

                </React.Fragment>
              }
            />
            {!sermon.published ? (
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => publishSermon(sermon)}
                >
                  <PublishIcon color="secondary" />
                </IconButton>
              </ListItemSecondaryAction>
            ) : (
              ""
            )}
          </ListItem>
          {index < sermons.length - 1 && (
            <Divider component="li" variant="inset" />
          )}
        </React.Fragment>
      ))}
    </List>
  );
}

export default Sermons;
