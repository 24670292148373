import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    executeVerify,
    selectVerified,
    selectIsLoading
} from "../redux/modules/email-verification";
import EmailVerification from "./EmailVerification";

function EmailVerificationContainer({ executeVerify, verified, isLoading,history }) {
  useEffect(() => {
    executeVerify();
  }, [executeVerify]);

  return <EmailVerification isLoading={isLoading} verified={verified} />;
}

const mapStateToProps = state => ({
    verified: selectVerified(state),
    isLoading: selectIsLoading(state)
});

const mapDipsatchToProps =  (dispatch, props) => ({
    executeVerify: () => dispatch(executeVerify(props.history))
});

export default withRouter(
    connect(mapStateToProps, mapDipsatchToProps)(EmailVerificationContainer)
);

