import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { changePassword } from "../redux/modules/change-password";
import ChangePasswordForm from "./ChangePasswordForm";

const mapStateToProps = state => ({
  state
});

const mapDispatchToProps = (dispatch, props) => ({
  changePassword: formData => dispatch(changePassword(formData, props.history))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ChangePasswordForm)
);
