import {
  AppBar,
  Breadcrumbs,
  Card,
  CardContent,
  Container,
  Fab,
  Grid,
  makeStyles,
  Toolbar,
  Typography
} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import WarningIcon from '@material-ui/icons/Warning';
import routes from "../route/routes";
import AddIcon from "@material-ui/icons/Add";
import React, { useState } from "react";
import LoginButtonContainer from "../auth/LoginButtonContainer";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4)
  },
  toolbar: theme.mixins.toolbar,
  grow: {
    flexGrow: 1
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
}));

function CardLink({ to, ...rest }) {
  const classes = useStyles();
  const [hoverState, setHoverState] = useState(false);

  return (
    <Link underline="none" component={RouterLink} to={to}>
      <Card
        raised={hoverState}
        onMouseOver={() => setHoverState(true)}
        onMouseOut={() => setHoverState(false)}
      >
        <CardContent className={classes.card} {...rest} />
      </Card>
    </Link>
  );
}


function WebcasterPage({ history }) {
  const classes = useStyles();

  function handleCreateSermon() {
    history.push("/sermons/upload");
  }

  return (

    <React.Fragment>
        <AppBar>
        <Toolbar>
          <span className={classes.grow}>Webcaster Portal</span>
          <LoginButtonContainer />
        </Toolbar>
      </AppBar>
      <Container maxWidth="md" className={classes.root}>
        <div className={classes.toolbar} />

        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary">Webcast</Typography>
        </Breadcrumbs>
        <Typography variant="h4" component="h1" gutterBottom>
          Webcast
        </Typography>

        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <CardLink to={routes.WEBCAST_UNPUBLISHED}>
              <HighlightOffIcon className={classes.cardIcon} color="primary" />
              <Typography variant="h6" component="h2" color="primary">
                Unpublished Sermons
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                Manage unpublished sermons
              </Typography>
            </CardLink>
          </Grid>

          <Grid item sm={6} xs={12}>
            <CardLink to={routes.WEBCAST_ORPHANED}>
              <WarningIcon className={classes.cardIcon} color="primary" />
              <Typography variant="h6" component="h2" color="primary">
                Orphaned Audio Files
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                Manage audio files that have no associated sermon
              </Typography>
            </CardLink>
          </Grid>
        </Grid>

      </Container>


      <Fab
        color="secondary"
        variant="extended"
        aria-label="add"
        className={classes.fab}
        onClick={handleCreateSermon}
      >
        <AddIcon className={classes.extendedIcon} />
        Create Sermon
      </Fab>
    </React.Fragment>
  );
}

export default WebcasterPage;
