import { default as React } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import AdminPage from "./admin/AdminPage";
import CongregationCreatePage from "./admin/congregation/CongregationCreatePage";
import CongregationEditPage from "./admin/congregation/CongregationEditPage";
import CongregationListPage from "./admin/congregation/CongregationListPage";
import EventCreatePage from "./admin/event/EventCreatePage";
import EventEditPage from "./admin/event/EventEditPage";
import EventListPage from "./admin/event/EventListPage";
import HolidayCreatePage from "./admin/holiday/HolidayCreatePage";
import HolidayEditPage from "./admin/holiday/HolidayEditPage";
import HolidayListPage from "./admin/holiday/HolidayListPage";
import MinisterCreatePage from "./admin/minister/MinisterCreatePage";
import MinisterEditPage from "./admin/minister/MinisterEditPage";
import MinisterListPage from "./admin/minister/MinisterListPage";
import SermonCreatePage from "./admin/sermon/SermonCreatePage";
import SermonUploadPage from "./admin/sermon/SermonUploadPage";
import UserAssignRolesPage from "./admin/user/UserAssignRolesPage";
import UserCreatePage from "./admin/user/UserCreatePage";
import UserListPage from "./admin/user/UserListPage";
import "./App.css";
import SermonDetailPage from "./archive/SermonDetailPage";
import SermonListPage from "./archive/SermonListPage";
import LoginPage from "./auth/LoginPage";
import ChangePasswordPage from "./auth/ChangePasswordPage";
import roles from "./auth/roles";
import SecuredRoute from "./auth/SecuredRoute";
import SnackbarContainer from "./common/SnackbarContainer";
import { initLoadUser, selectIsLoaded } from "./redux/modules/auth";
import routes from "./route/routes";
import WebcasterPage from "./webcaster/WebcasterPage";
import OrphanedAudioFileListPage from "./webcaster/orphaned/OrphanedAudioFileListPage";
import UnpublishedSermonListPage from "./webcaster/unpublished/UnpublishedSermonListPage";
import EmailVerifyPage from "./email/EmailVerifyPage";
import GoogleAnalytics from './analytics/GoogleAnalytics';
import SermonEditPage from "./archive/SermonEditPage";

function App({ initLoadUser, isAuthLoaded }) {
  if (!isAuthLoaded) {
    initLoadUser();
    return null;
  }

  return (
    <Router>
      <div className="App">
        <Switch>
          <SecuredRoute
            path={routes.SERMON_CREATE}
            requiredRole={roles.CREATE_SERMON}
            exact
            component={SermonCreatePage}
          />
          <SecuredRoute
            path={routes.SERMON_UPLOAD}
            requiredRole={roles.CREATE_AUDIO_FILE}
            exact
            component={SermonUploadPage}
          />
          <Route path={routes.SERMON_LIST} exact component={SermonListPage} />
          <Route
            path={routes.SERMON_DETAILS}
            exact
            component={SermonDetailPage}
          />
          <SecuredRoute
            path={routes.SERMON_EDIT}
            requiredRole={roles.EDIT_SERMON}
            exact
            component={SermonEditPage}
          />
          <SecuredRoute
            path={routes.ADMIN}
            requiredRole={roles.CREATE_USER}
            exact
            component={AdminPage}
          />
          <SecuredRoute
            path={routes.ADMIN_USER_LIST}
            requiredRole={roles.CREATE_USER}
            exact
            component={UserListPage}
          />
          <SecuredRoute
            path={routes.ADMIN_USER_CREATE}
            requiredRole={roles.CREATE_USER}
            exact
            component={UserCreatePage}
          />
          <SecuredRoute
            path={routes.ADMIN_EDIT_USER_ROLES}
            requiredRole={roles.CREATE_USER}
            exact
            component={UserAssignRolesPage}
          />
          <SecuredRoute
            path={routes.ADMIN_MINISTER_LIST}
            requiredRole={roles.READ_MINISTER}
            exact
            component={MinisterListPage}
          />
          <SecuredRoute
            path={routes.ADMIN_MINISTER_CREATE}
            requiredRole={roles.CREATE_MINISTER}
            exact
            component={MinisterCreatePage}
          />
          <SecuredRoute
            path={routes.ADMIN_MINISTER_EDIT}
            requiredRole={roles.EDIT_MINISTER}
            exact
            component={MinisterEditPage}
          />
          <SecuredRoute
            path={routes.ADMIN_CONGREGATION_LIST}
            requiredRole={roles.READ_CONGREGATION}
            exact
            component={CongregationListPage}
          />
          <SecuredRoute
            path={routes.ADMIN_CONGREGATION_CREATE}
            requiredRole={roles.CREATE_CONGREGATION}
            exact
            component={CongregationCreatePage}
          />
          <SecuredRoute
            path={routes.ADMIN_CONGREGATION_EDIT}
            requiredRole={roles.EDIT_CONGREGATION}
            exact
            component={CongregationEditPage}
          />
          <SecuredRoute
            path={routes.WEBCAST}
            requiredRole={roles.CREATE_SERMON}
            exact
            component={WebcasterPage}
          />
          <SecuredRoute
            path={routes.WEBCAST_ORPHANED}
            requiredRole={roles.CREATE_SERMON}
            exact
            component={OrphanedAudioFileListPage}
          />
          <SecuredRoute
            path={routes.WEBCAST_UNPUBLISHED}
            requiredRole={roles.PUBLISH_SERMON}
            exact
            component={UnpublishedSermonListPage}
          />
          <SecuredRoute
            path={routes.ADMIN_EVENT_LIST}
            requiredRole={roles.EDIT_EVENT}
            exact
            component={EventListPage}
          />
          <SecuredRoute
            path={routes.ADMIN_EVENT_CREATE}
            requiredRole={roles.CREATE_EVENT}
            exact
            component={EventCreatePage}
          />
          <SecuredRoute
            path={routes.ADMIN_EVENT_EDIT}
            requiredRole={roles.EDIT_EVENT}
            exact
            component={EventEditPage}
          />

          <SecuredRoute
            path={routes.ADMIN_HOLIDAY_LIST}
            requiredRole={roles.EDIT_HOLIDAY}
            exact
            component={HolidayListPage}
          />
          <SecuredRoute
            path={routes.ADMIN_HOLIDAY_CREATE}
            requiredRole={roles.CREATE_HOLIDAY}
            exact
            component={HolidayCreatePage}
          />
          <SecuredRoute
            path={routes.ADMIN_HOLIDAY_EDIT}
            requiredRole={roles.EDIT_HOLIDAY}
            exact
            component={HolidayEditPage}
          />

          <Route path={routes.LOGIN} exact component={LoginPage} />
          <Route path={routes.CHANGE_PASSWORD} exact component={ChangePasswordPage} />
          <Route path={routes.EMAIL_VERIFY} exact component={EmailVerifyPage} />
          <Redirect
            from="/"
            to={{ pathname: routes.SERMON_LIST, search: "?congregation_id=0" }}
          />
        </Switch>
        <SnackbarContainer />
        <GoogleAnalytics />
      </div>
    </Router>
  );
}

const mapStateToProps = state => ({
  isAuthLoaded: selectIsLoaded(state)
});

const mapDispatchToProps = dispatch => ({
  initLoadUser: () => dispatch(initLoadUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
