import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  cancelForm,
  saveForm,
  selectEvent
} from "../../redux/modules/events";
import EventDetailsForm from "./EventDetailsForm";

const EventDetailsFormContainer = ({
  event,
  saveForm,
  cancel
}) => {
  return (
    <EventDetailsForm
      event={event}
      saveForm={saveForm}
      cancel={cancel}
    />
  );
};

const getEventIdParam = props =>
  Number.parseInt(props.match.params.eventId, 10);

const mapStateToProps = (state, props) => ({
  event: selectEvent(getEventIdParam(props), state)
});

const mapDispatchToProps = (dispatch, props) => ({
  saveForm: formData => dispatch(saveForm(formData, props.history)),
  cancel: () => dispatch(cancelForm(props.history))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EventDetailsFormContainer)
);
