const roles = {
  CREATE_SERMON: "CREATE_SERMON",
  EDIT_SERMON: "EDIT_SERMON",
  DELETE_SERMON: "DELETE_SERMON",
  PUBLISH_SERMON: "PUBLISH_SERMON",
  UNPUBLISH_SERMON: "UNPUBLISH_SERMON",
  CREATE_AUDIO_FILE: "CREATE_AUDIO_FILE",
  CREATE_USER: "CREATE_USER",
  READ_MINISTER: "READ_MINISTER",
  CREATE_MINISTER: "CREATE_MINISTER",
  EDIT_MINISTER: "EDIT_MINISTER",
  READ_CONGREGATION: "READ_CONGREGATION",
  CREATE_CONGREGATION: "CREATE_CONGREGATION",
  EDIT_CONGREGATION: "EDIT_CONGREGATION",
  DISABLE_CONGREGATION: "DISABLE_CONGREGATION",
  ENABLE_CONGREGATION: "ENABLE_CONGREGATION",
  READ_EVENT: "READ_EVENT",
  CREATE_EVENT: "CREATE_EVENT",
  EDIT_EVENT: "EDIT_EVENT",
  READ_HOLIDAY: "READ_HOLIDAY",
  CREATE_HOLIDAY: "CREATE_HOLIDAY",
  EDIT_HOLIDAY: "EDIT_HOLIDAY"
};

export default roles;
