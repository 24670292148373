import { combineReducers } from "redux";
import audioFileState from "./modules/audio-files";
import authState from "./modules/auth";
import congregationState from "./modules/congregations";
import menuState from "./modules/menu";
import ministerState from "./modules/ministers";
import emailVerificationState from "./modules/email-verification";
import eventState from "./modules/events";
import holidayState from "./modules/holidays";
import roleState from "./modules/roles";
import sermonFilterState from "./modules/sermon-filters";
import sermonFormState from "./modules/sermon-form";
import sermonState from "./modules/sermons";
import snackbarState from "./modules/snackbar";
import unpublishedSermonState from "./modules/unpublished-sermons";
import userFormState from "./modules/user-form";
import userState from "./modules/users";

export default combineReducers({
  menuState,
  authState,
  sermonState,
  unpublishedSermonState,
  sermonFormState,
  sermonFilterState,
  congregationState,
  userState,
  userFormState,
  roleState,
  ministerState,
  eventState,
  emailVerificationState,
  holidayState,
  audioFileState,
  snackbarState
});
