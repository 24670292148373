import api from "../../api";
import httpClient from "../../http/http-client";
import routes from "../../route/routes";
import { showSnackbar } from "./snackbar";

const REQUEST_MINISTERS = "llc-archive/minister/REQUEST_MINISTERS";
const requestMinisters = () => ({
  type: REQUEST_MINISTERS
});

const RECEIVE_MINISTERS = "llc-archive/minister/RECEIVE_MINISTERS";
const receiveMinisters = ministers => ({
  type: RECEIVE_MINISTERS,
  ministers
});

const REQUEST_MINISTER = "llc-archive/minister/REQUEST_MINISTER";
const requestMinister = () => ({
  type: REQUEST_MINISTER
});

const RECEIVE_MINISTER = "llc-archive/minister/RECEIVE_MINISTER";
const receiveMinister = minister => ({
  type: RECEIVE_MINISTER,
  minister
});

export const fetchMinisters = () => dispatch => {
  dispatch(requestMinisters());
  return httpClient
    .get(api.ministers)
    .then(resp => resp.json())
    .then(resp => dispatch(receiveMinisters(resp)));
};

export const fetchMinister = ministerId => (dispatch, getState) => {
  const minister = selectMinister(ministerId, getState());
  if (minister) {
    return;
  }
  dispatch(requestMinister());

  return httpClient
    .get(api.minister(ministerId))
    .then(resp => resp.json())
    .then(resp => dispatch(receiveMinister(resp)));
};

export const saveForm = (formData, history) => dispatch => {
  const editing = !!formData.id;
  const method = editing ? httpClient.put : httpClient.post;
  const url = editing ? api.minister(formData.id) : api.ministers;

  return method(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(formData)
  })
    .then(resp => {
      if (resp.ok) {
        dispatch(showSnackbar("Minister Saved!"));
        return resp.json();
      } else {
        const message =
          resp.status === 403
            ? "You don't have permission to edit ministers"
            : "Failed to save the minister";
        console.log("resp", resp);
        throw new Error(message);
      }
    })
    .then(resp => {
      history.push(routes.ADMIN_MINISTER_LIST);
    })
    .catch(error => {
      dispatch(showSnackbar(error.message, "error"));
    });
};

export const cancelForm = history => dispatch => {
  history.push(routes.ADMIN_MINISTER_LIST);
};

const initialState = {
  ministers: [],
  isLoading: false
};

export const selectMinisters = state => state.ministerState.ministers;

export const selectMinister = (ministerId, state) =>
  state.ministerState.ministers.find(minister => minister.id === ministerId);

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST_MINISTERS:
      return {
        ...state,
        isLoading: true
      };

    case RECEIVE_MINISTERS:
      return {
        ...state,
        isLoading: false,
        ministers: action.ministers
      };

    case RECEIVE_MINISTER:
      return {
        ...state,
        ministers: [...state.ministers, action.minister]
      };

    default:
      return state;
  }
}
