import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow
  } from "@material-ui/core";
  import EditIcon from "@material-ui/icons/Edit";
  import React, { useState } from "react";
  import { Link as RouterLink } from "react-router-dom";
  import routes from "../../route/routes";
  
  function HolidayList({ holidays }) {
    const rowsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(0);
  
    const handlePageChange = (holiday, page) => {
      setCurrentPage(page);
    };
  
    return (
      <Paper>
        {holidays.length ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Holiday</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {holidays
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .slice(
                    currentPage * rowsPerPage,
                    currentPage * rowsPerPage + rowsPerPage
                  )
                  .map((holiday, idx) => (
                    <TableRow key={idx}>
                      <TableCell>
                        <b>{holiday.name}</b><i>{holiday.theme ? ' : ' + holiday.theme : ''}</i></TableCell>
                      <TableCell align="right">
                        <IconButton
                          size="small"
                          component={RouterLink}
                          to={routes.ADMIN_HOLIDAY_EDIT.replace(
                            ":holidayId",
                            holiday.id
                          )}
                        >
                          <EditIcon color="primary" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    page={currentPage}
                    count={holidays.length}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handlePageChange}
                  ></TablePagination>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        ) : (
          ""
        )}
      </Paper>
    );
  }
  
  export default HolidayList;
  