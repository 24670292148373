import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { cancelForm, upload, selectIsLoading, } from "../../redux/modules/sermon-form";
import SermonFormUpload from "./SermonFormUpload";

const mapStateToProps = state => ({
  isLoading: selectIsLoading(state)
});

const mapDispatchToProps = (dispatch, props) => ({
  upload: file => dispatch(upload(file, props.history)),
  cancel: () => dispatch(cancelForm(props.history))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SermonFormUpload)
);
