import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper
} from "@material-ui/core";
import React from "react";
import Select from "react-select";

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(2),
    borderTopStyle: "dashed",
    borderTopWidth: 1,
    borderTopColor: theme.palette.grey[500]
  },

  footer: {
    display: "flex",
    flexDirection: "row",
    borderTopStyle: "dashed",
    borderTopWidth: 1,
    borderTopColor: theme.palette.grey[500],
    padding: theme.spacing(2)
  },
  footerButton: {
    flex: 1,
    marginRight: theme.spacing(1)
  },
  ml4: {
    marginLeft: theme.spacing(4)
  },
  mt0: {
    marginTop: 0
  },
  required: {
    height: 0,
    opacity: 0,
    width: "100%",
    position: "absolute",
    bottom: 0
  },
  requiredContainer: {
    position: "relative"
  }
}));

function UserRolesForm({ userId, user, roles, saveForm, cancel }) {
  const classes = useStyles();

  const [formData, setFormData] = React.useState({
    userId,
    role_id: 0,
    congregations: []
  });

  const handleSubmit = event => {
    event.preventDefault();
    saveForm(formData);
  };

  const handleRoleChange = role => {
    // If only one congregation, it will automatically
    // be selected and hidden from the UI
    setFormData({
      ...formData,
      role_id: role.id,
      congregations: role.congregations.length === 1 ? role.congregations : []
    });
  };

  const handleCongregationSelect = congregations => {
    setFormData({ ...formData, congregations });
  };

  return (
    <Paper>
      <form onSubmit={handleSubmit}>
        <Stepper activeStep={1}>
          <Step>
            <StepLabel>User Details</StepLabel>
          </Step>
          <Step>
            <StepLabel>Assign Role</StepLabel>
          </Step>
        </Stepper>
        <div className={classes.content}>
          {user ? (
            <p className={classes.mt0}>
              Assign role to{" "}
              <strong>
                {user.first_name} {user.last_name}
              </strong>
              :
            </p>
          ) : (
            ""
          )}

          <FormControl component="fieldset" fullWidth>
            <RadioGroup value={formData.role_id}>
              <FormLabel component="legend">Role</FormLabel>
              {roles.map(role => (
                <React.Fragment key={role.name}>
                  <FormControlLabel
                    label={role.description}
                    value={role.id}
                    control={
                      <Radio
                        name="role"
                        onChange={e => handleRoleChange(role)}
                        value={role.id}
                        required
                      />
                    }
                  />
                  <div className={classes.ml4}>
                    {role.congregations.length > 1 && (
                      <div className={classes.requiredContainer}>
                        <Select
                          type="congregation"
                          placeholder={"Select Congregations..."}
                          isDisabled={role.id !== formData.role_id}
                          onChange={value => handleCongregationSelect(value)}
                          options={role.congregations}
                          getOptionLabel={congregation => congregation.name}
                          getOptionValue={congregation => congregation.id}
                          isMulti
                        />
                        {/* Workaround until react-select provides a way to set required */}
                        {role.id === formData.role_id && (
                          <input
                            tabIndex={-1}
                            autoComplete="off"
                            className={classes.required}
                            value={formData.congregations.length ? "valid" : ""}
                            required
                            onChange={() => ""}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </React.Fragment>
              ))}
            </RadioGroup>
          </FormControl>
        </div>
        <div className={classes.footer}>
          <Button
            className={classes.footerButton}
            type="submit"
            color="primary"
            variant="contained"
          >
            Save
          </Button>
          <Button
            className={classes.footerButton}
            onClick={cancel}
            type="button"
          >
            Cancel
          </Button>
        </div>
      </form>
    </Paper>
  );
}

export default UserRolesForm;
