import {
    AppBar,
    Box,
    Container,
    makeStyles,
    Toolbar,
    Typography
} from "@material-ui/core";
import React from "react";
import LoginButtonContainer from "../auth/LoginButtonContainer"
import EmailVerificationContainer from "./EmailVerificationContainer";
import queryString from "query-string";

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(1)
    },
    grow: {
        flexGrow: 1
    },
    toolbar: theme.mixins.toolbar
}));


function EmailVerifyPage({ history }) {

    const classes = useStyles();
    const params = queryString.parse(history.location.search);

    return (
        <React.Fragment>
            <AppBar>
                <Toolbar>
                    <span className={classes.grow}>Verify Email</span>
                    <LoginButtonContainer />
                </Toolbar>
            </AppBar>
            <Container maxWidth="md" className={classes.root}>
                <div className={classes.toolbar} />
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start">

                    <Typography variant="h4" component="h1" gutterBottom>
                        Email Verification
                        </Typography>
                </Box>
                <EmailVerificationContainer params={params} />
            </Container>
        </React.Fragment>
    )
}

export default EmailVerifyPage;