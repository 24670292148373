import { Box, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import ReactPlayer from "react-player";
import BibleReference from "../bible/bibleReference";

const useStyles = makeStyles(theme => ({
  title: {
    margin: 0
  },
  content: {
    padding: theme.spacing(2)
  },
  player: {
    maxWidth: "100%",
    maxHeight: "55px"
  },
  speedButton: {
    textAlign: 'center'
  }
}));

const speeds = [1.0, 1.5, 2.0];

function SermonDetails({ sermon }) {
  const classes = useStyles();

  const [playerConfig, setPlayerConfig] = React.useState({
    playbackRate: speeds[0]
  });

  const handleSetPlaybackRate = speed => {
    setPlayerConfig({ ...playerConfig, playbackRate: parseFloat(speed) })
  }

  const determineColor = speed => {
    if (playerConfig.playbackRate === parseFloat(speed)) {
      return "primary"
    }
  }

  return (
    <div className={classes.content}>
      <Typography variant="h5" component="h5">
        {sermon.bible_references.map((reference, index) => (
          <React.Fragment key={index.toString()}>
            {index !== 0 ? ", " : ""}
            <BibleReference reference={reference} />
          </React.Fragment>
        ))}
      </Typography>

      <Typography variant="subtitle1" color="textSecondary">
        <span>
          {sermon.minister.first_name} {sermon.minister.last_name}
        </span>
        <Box paddingX={1} component="span">
          <span>&#8226;</span>
        </Box>
        <span>
          {new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "long",
            day: "2-digit"
          }).format(new Date(sermon.spoken_timestamp))}
        </span>
      </Typography>
      <Box paddingTop={2}>
        <ReactPlayer
          className={classes.player}
          url={sermon.audio_file.file_url}
          playbackRate={parseFloat(playerConfig.playbackRate)}
          controls
          playing
        />
      </Box>
      <Box paddingTop={1} width={1}>
          {speeds.map((speed, i) => {
            return (
                <Button key={i} variant="outlined"
                  className={classes.speedButton}
                  size="small"
                  color={determineColor(speed)} disableElevation
                  width={1}
                  onClick={() => handleSetPlaybackRate(speed)}>{speed}x</Button>
            )
          })}
      </Box>
    </div>
  );
}

export default SermonDetails;
