import {
  refreshToken,
  selectExpiresAt,
  selectIsLoggedIn
} from "./modules/auth";

let refreshTokenPromise = null;

export const authMiddleware = store => next => action => {
  if (typeof action === "function") {
    if (selectIsLoggedIn(store.getState())) {
      var tokenExpiration = selectExpiresAt(store.getState());

      // Refresh if token expires within 5 minutes from now
      if (tokenExpiration && tokenExpiration - Date.now() < 300000) {
        if (!refreshTokenPromise) {
          refreshTokenPromise = next(refreshToken());
        }

        return refreshTokenPromise.then(() => {
          refreshTokenPromise = null;
          next(action);
        });
      }
    }
  }
  return next(action);
};
