import { makeStyles } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import React from "react";

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(1)
    },
    grow: {
        flexGrow: 1
    },
    container: {
        position: "relative"
    }
}));


function EmailVerification({ isLoading, verified }) {
    const classes = useStyles();

    let content;
    if (isLoading) {
        content = <Alert severity="info">Requesting Email Verification</Alert>
    } else if (!isLoading && !verified) {
        content = <Alert severity="error">Unable to verify email address!</Alert>
    } else if (!isLoading && verified) {
        content = <Alert severity="success">Succesfully verified email address!</Alert>
    }

    return (
        <React.Fragment>
          <div className={classes.container}>{content}</div>
        </React.Fragment>
      );
}

export default EmailVerification;