import {
  AppBar,
  Breadcrumbs,
  Container,
  Link,
  makeStyles,
  Toolbar,
  Typography
} from "@material-ui/core";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import LoginButtonContainer from "../../auth/LoginButtonContainer";
import { fetchMinister, selectMinister } from "../../redux/modules/ministers";
import routes from "../../route/routes";
import MinisterDetailsFormContainer from "./MinisterDetailsFormContainer";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1)
  },
  grow: {
    flexGrow: 1
  },
  toolbar: theme.mixins.toolbar
}));

const extractMinisterId = match => Number.parseInt(match.params.ministerId, 10);

function MinisterEditPage({ match, fetchMinister, minister }) {
  const classes = useStyles();
  const ministerId = extractMinisterId(match);

  useEffect(() => {
    fetchMinister(ministerId);
  }, [fetchMinister, ministerId]);

  return (
    <React.Fragment>
      <AppBar>
        <Toolbar>
          <span className={classes.grow}>Admin</span>
          <LoginButtonContainer />
        </Toolbar>
      </AppBar>
      <Container maxWidth="md" className={classes.root}>
        <div className={classes.toolbar} />
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} color="inherit" to={routes.ADMIN}>
            Admin
          </Link>
          <Link
            component={RouterLink}
            color="inherit"
            to={routes.ADMIN_MINISTER_LIST}
          >
            Ministers
          </Link>
          <Typography color="textPrimary">Edit Minister</Typography>
        </Breadcrumbs>
        <Typography variant="h4" component="h1" gutterBottom>
          Edit Minister
        </Typography>

        {minister && <MinisterDetailsFormContainer />}
      </Container>
    </React.Fragment>
  );
}

const mapStateToProps = (state, props) => ({
  minister: selectMinister(extractMinisterId(props.match), state)
});

const mapDispatchToProps = dispatch => ({
  fetchMinister: ministerId => dispatch(fetchMinister(ministerId))
});

export default connect(mapStateToProps, mapDispatchToProps)(MinisterEditPage);
