import api from "../../api";
import httpClient from "../../http/http-client";

const REQUEST = "llc-archive/unpublished-sermons/REQUEST";
const requestUnpublishedSermons = () => ({
  type: REQUEST
});

const RECEIVE = "llc-archive/unpublished-sermons/RECEIVE";
const receiveUnpublishedSermons = sermons => ({
  type: RECEIVE,
  sermons
});

const PUBLISHED = "llc-archive/unpublished-sermons/PUBLISHED";
const sermonPublished = sermon => ({
  type: PUBLISHED,
  sermon
});

export const fetchUnpublishedSermons = () => dispatch => {
  dispatch(requestUnpublishedSermons());
  return httpClient
    .get(api.unpublishedSermons)
    .then(resp => {
      if (!resp.ok) {
        const error = (resp.data && resp.data.message) || resp.statusText;
        return Promise.reject(error);
      }
      return resp;
    })
    .then(resp => resp.json())
    .then(resp => dispatch(receiveUnpublishedSermons(resp.sermons)));
};

export const publishSermon = sermon => dispatch => {
  return httpClient
    .post(`${api.sermons}/${sermon.id}/publish`)
    .then(resp => {
      if (!resp.ok) {
        const error = (resp.data && resp.data.message) || resp.statusText;
        return Promise.reject(error);
      }
      return resp;
    })
    .then(resp => resp.json())
    .then(resp => dispatch(sermonPublished(resp)));
};

const initialState = {
  unpublishedSermons: [],
  isLoading: false
};

export const selectUnpublishedSermons = state =>
  state.unpublishedSermonState.unpublishedSermons;

export const selectIsLoading = state => state.unpublishedSermonState.isLoading;

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case RECEIVE:
      return {
        ...state,
        unpublishedSermons: action.sermons,
        isLoading: false
      };

    case PUBLISHED:
      return {
        ...state,
        unpublishedSermons: state.unpublishedSermons.filter(
          sermon => sermon.id !== action.sermon.id
        )
      };

    default:
      return state;
  }
}
