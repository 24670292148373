import api from "../../api";
import httpClient from "../../http/http-client";
import { showSnackbar } from "./snackbar";
import queryString from "query-string";

const REQUEST_VERIFY = "llc-archive/email/REQUEST_VERIFY";
const requestVerify = () => ({
  type: REQUEST_VERIFY
});


const RECEIVE_VERIFY = "llc-archive/email/RECEIVE_VERIFY";
const receiveVerify = (verified) => ({
  type: RECEIVE_VERIFY,
  verified
});

export const executeVerify = history => dispatch => {
    let params = queryString.parse(history.location.search);
  dispatch(requestVerify());
  return httpClient
    .get(`${api.verifyEmail}?${queryString.stringify(params)}`)
    .then(resp => { 
        if (resp.ok) {
            dispatch(receiveVerify(true));
            dispatch(showSnackbar("Email Verified!"));
          } else {
            dispatch(receiveVerify(false));
            dispatch(showSnackbar("Unable to verify email address", "error"));
          }
    })
};

const initialState = {
    verified: false,
    isLoading: true
};

export const selectIsLoading = state => state.emailVerificationState.isLoading;
export const selectVerified = state => state.emailVerificationState.verified;

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST_VERIFY:
      return {
        ...state,
        isLoading: true,
        verified: false
      };

    case RECEIVE_VERIFY:
    return {
        ...state,
        isLoading: false,
        verified: action.verified
    };

    default:
      return state;
  }
}
