import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  fetchCongregations,
  selectCongregations
} from "../../redux/modules/congregations";
import {
  cancelForm,
  saveForm,
  selectMinister
} from "../../redux/modules/ministers";
import MinisterDetailsForm from "./MinisterDetailsForm";

const MinisterDetailsFormContainer = ({
  fetchCongregations,
  minister,
  congregations,
  saveForm,
  cancel
}) => {
  useEffect(() => {
    fetchCongregations();
  }, [fetchCongregations]);

  return (
    <MinisterDetailsForm
      minister={minister}
      congregations={congregations}
      saveForm={saveForm}
      cancel={cancel}
    />
  );
};

const getMinisterIdParam = props =>
  Number.parseInt(props.match.params.ministerId, 10);

const mapStateToProps = (state, props) => ({
  minister: selectMinister(getMinisterIdParam(props), state),
  congregations: selectCongregations(state)
});

const mapDispatchToProps = (dispatch, props) => ({
  fetchCongregations: () => dispatch(fetchCongregations()),
  saveForm: formData => dispatch(saveForm(formData, props.history)),
  cancel: () => dispatch(cancelForm(props.history))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MinisterDetailsFormContainer)
);
