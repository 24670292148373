import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchUsers, selectMeta, selectUsers } from "../../redux/modules/users";
import UserList from "./UserList";

function UserListContainer({ fetchUsers, users, meta, pageChange }) {
  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return <UserList users={users} meta={meta} pageChange={pageChange} />;
}

const mapStateToProps = state => ({
  users: selectUsers(state),
  meta: selectMeta(state)
});

const mapDispatchToProps = dispatch => ({
  fetchUsers: () => dispatch(fetchUsers()),
  pageChange: page => dispatch(fetchUsers(page))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserListContainer);
